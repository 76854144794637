import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  StockHistory,
  AddCustomerInvoice,
  CustomerInvoices,
  VehicleStock,
  MainStock,
  JobNotes,
  ThirdPartyStock,
  ColomboShowRoomStock,
  AddDirectJobNote,
  DirectJobNotes,
  AddJobNoteServiceVehicel,
  AddJobNoteThirdPartyAgent,
  ThirdPartyJobNotes,
  AddDirectJobNoteColombo,
  DirectJobNotesColombo,
} from './pages/dashboard';
import SharedLayout from './pages/dashboard/SharedLayout';
import { ProtectedRoute, SignIn } from './pages/index';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<MainStock />} />
          <Route path="vehicle-stock" element={<VehicleStock />} />
          <Route path="third-Party-Stock" element={<ThirdPartyStock />} />
          <Route path="job-notes" element={<JobNotes />} />
          <Route path="add-customer-invoice" element={<AddCustomerInvoice />} />
          <Route path="customer-invoices" element={<CustomerInvoices />} />
          <Route path="add-direct-jobnote" element={<AddDirectJobNote />} />
          <Route path="direct-jobnotes" element={<DirectJobNotes />} />
          <Route path="vehicle-job-notes" element={<JobNotes />} />
          <Route path="add-jobnotes" element={<AddJobNoteServiceVehicel />} />
          <Route path="colombo-showroom" element={<ColomboShowRoomStock />} />
          <Route path="stock-history" element={<JobNotes />} />
          <Route path="add-jobnotes-third-party" element={<AddJobNoteThirdPartyAgent />} />
          <Route path="thirdparty-jobnotes" element={<ThirdPartyJobNotes />} />
          <Route path="add-direct-jobnote-colombo" element={<AddDirectJobNoteColombo />} />
          <Route path="direct-jobnotes-colombo" element={<DirectJobNotesColombo />} />
        </Route>
        <Route path="/login" element={<SignIn />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
