import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/AuthFetch';

const initialState = {
  isLoading: false,
  showAlert: false,
  alertType: '',
  alertText: '',
  searchProducts: [],
  customerInvoices: [],
  customerInvoiceProducts: [],
};

export const createCustomerInovice = createAsyncThunk('CustomerInvoice/createCustomerInovice', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`product/customerInvoices`, data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note
export const getProductsForSearch = createAsyncThunk('CustomerInvoice/getProductsForSearch', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/directJobNote?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note
export const getCustomerInvoices = createAsyncThunk('CustomerInvoice/getCustomerInvoices', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/customerInvoices?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note
export const getCustomerInvoiceProduct = createAsyncThunk('CustomerInvoice/getCustomerInvoiceProduct', async (invoiceNo, thunkAPI) => {
  try {
    console.log('====================================');
    console.log('${invoiceNo}', invoiceNo);
    console.log('====================================');
    const resp = await axiosInstance.get(`product/customerInvoice/${invoiceNo}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

const CustomerInvoiceSlice = createSlice({
  name: 'CustomerInvoice',
  initialState,
  reducers: {
    displayAlert: (state, { payload }) => {
      state.showAlert = true;
      state.alertType = payload ? payload.alertType : 'error';
      state.alertText = payload ? payload.alertText : 'Please provide all values';
    },
    hideAlert: (state) => {
      state.showAlert = false;
      state.alertType = '';
      state.alertText = '';
    },
  },

  extraReducers: (builder) => {
    builder
      //createCustomerInovice
      .addCase(createCustomerInovice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCustomerInovice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully customer invoice is created';
      })
      .addCase(createCustomerInovice.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getProductsForSearch
      .addCase(getProductsForSearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsForSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchProducts = action.payload;
      })
      .addCase(getProductsForSearch.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getCustomerInvoices
      .addCase(getCustomerInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerInvoices = action.payload;
      })
      .addCase(getCustomerInvoices.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getCustomerInvoiceProduct
      .addCase(getCustomerInvoiceProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerInvoiceProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerInvoiceProducts = action.payload;
      })
      .addCase(getCustomerInvoiceProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      });
  },
});
export const { displayAlert, hideAlert, isLoading } = CustomerInvoiceSlice.actions;

export default CustomerInvoiceSlice.reducer;
