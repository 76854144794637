import React from 'react';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';

const BtnContainer = ({ numOfPages, page, changePageNumber }) => {
  const dispatch = useDispatch();

  const renderPageNumbers = () => {
    const maxPages = 3; // Maximum number of pages to display
    let startPage = Math.max(1, page - Math.floor(maxPages / 2));
    let endPage = Math.min(numOfPages, startPage + maxPages - 1);
    if (endPage - startPage + 1 < maxPages) {
      startPage = Math.max(1, endPage - maxPages + 1);
    }
    const pages = [];
    if (startPage > 1) {
      pages.push(
        <button
          key="first"
          className="text-white py-2 px-3 bg-slate-800 duration-300 border-transparent w-[50px] h-[40px] rounded-md font-semibold cursor-pointer  hover:bg-slate-500"
          type="button"
          onClick={() => dispatch(changePageNumber(1))}
        >
          1
        </button>,
        <span key="dots1" className="mx-2">
          ...
        </span>
      );
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`text-white py-2 px-3 bg-slate-800 duration-300 border-transparent w-[50px] h-[40px]  rounded-md font-semibold cursor-pointer  hover:bg-slate-500  ${
            i === page && 'text-white bg-cyan-700'
          }`}
          type="button"
          onClick={() => dispatch(changePageNumber(i))}
        >
          {i}
        </button>
      );
    }
    if (endPage < numOfPages) {
      pages.push(
        <span key="dots2" className="mx-2">
          ...
        </span>,
        <button
          key="last"
          className="text-white py-2 px-3 bg-slate-800  duration-300 border-transparent w-[50px] h-[40px]  rounded-md font-semibold cursor-pointer"
          type="button"
          onClick={() => dispatch(changePageNumber(numOfPages))}
        >
          {numOfPages}
        </button>
      );
    }
    return pages;
  };

  const nextPage = () => {
    let newPage = page + 1;
    if (newPage > numOfPages) {
      newPage = 1;
    }
    dispatch(changePageNumber(newPage));
  };

  const prevPage = () => {
    let newPage = page - 1;
    if (newPage < 1) {
      newPage = numOfPages;
    }
    dispatch(changePageNumber(newPage));
  };

  return (
    <div className="h-24 mt-8 flex items-center justify-end gap-2">
      <button
        className="text-white py-2 px-3 bg-slate-800 border border-transparent rounded capitalize flex items-center gap-2 cursor-pointer hover:bg-cyan-600 hover:text-white"
        onClick={prevPage}
      >
        <HiChevronDoubleLeft /> Prev
      </button>

      <div className="flex gap-2">{renderPageNumbers()}</div>

      <button
        className=" text-white py-2 px-3 bg-slate-800 border border-transparent rounded capitalize flex items-center gap-2 hover:bg-cyan-600 hover:text-white"
        onClick={nextPage}
      >
        Next <HiChevronDoubleRight />
      </button>
    </div>
  );
};

export default BtnContainer;
