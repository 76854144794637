import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../../constants';
import axiosInstance from '../../utils/AuthFetch';

const initialState = {
  ColomboShowroomStockItems: [],
  totalProducts: 1,
  numOfPages: 1,
  page: 1,
  isLoading: false,
  errorMsg: 'is currently empty',
  showAlert: false,
  alertType: '',
  alertText: '',
};

export const getColomboShowroomStockItems = createAsyncThunk('ColomboShowroomStock/getColomboShowroomStockItems', async (searchText, thunkAPI) => {
  try {
    const { page } = thunkAPI.getState().MainStock;
    let pagePram = page;

    const resp = await axiosInstance.get(`colomboshowroom?searchText=${searchText}&page=${pagePram}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

export const addProduct = createAsyncThunk('ColomboShowroomStock/addProduct', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}colomboshowroom`, data);
    thunkAPI.dispatch(getColomboShowroomStockItems(''));
    return resp.data;
  } catch (error) {
    console.log('error.response.data.msg', error.response.data.msg);
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const editProduct = createAsyncThunk('ColomboShowroomStock/editProduct', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.put(`${BASE_URL}colomboshowroom/${data.id}`, data);
    thunkAPI.dispatch(getColomboShowroomStockItems(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

export const addQuntity = createAsyncThunk('ColomboShowroomStock/addQuntity', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.patch(`${BASE_URL}colomboshowroom/addQuntity/${data.id}`, { quantity: data.quantityValue });
    thunkAPI.dispatch(getColomboShowroomStockItems(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

const ColomboShowroomStockSlice = createSlice({
  name: 'ColomboShowroomStock',
  initialState,
  reducers: {
    changePageNumber: (state, { payload }) => {
      state.page = payload;
    },
    displayAlert: (state) => {
      state.showAlert = true;
      state.alertType = 'error';
      state.alertText = 'Please provide all values';
    },
    hideAlert: (state) => {
      state.showAlert = false;
      state.alertType = '';
      state.alertText = '';
    },
  },

  extraReducers: (builder) => {
    builder
      //get Products
      .addCase(getColomboShowroomStockItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getColomboShowroomStockItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ColomboShowroomStockItems = action.payload.products;
        state.totalProducts = action.payload.totalProducts;
        state.numOfPages = action.payload.numOfPages;
      })
      .addCase(getColomboShowroomStockItems.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })
      //add Product
      .addCase(addProduct.pending, (state) => {
        state.isLoading = true;
        state.showAlert = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showAlert = true;
        state.alertType = 'success';
        state.alertText = 'Product Added Successfully';
        // state.MainStockItems = action.payload;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.showAlert = true;
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })
      //update Product
      .addCase(editProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully product is updated';
      })
      .addCase(editProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })
      //addQuntity
      .addCase(addQuntity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQuntity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully product quntity updated';
      })
      .addCase(addQuntity.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      });
  },
});
export const { changePageNumber, displayAlert, hideAlert } = ColomboShowroomStockSlice.actions;

export default ColomboShowroomStockSlice.reducer;
