import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayAlert, hideAlert, registerThirdParty } from '../../features/ThirdPartyStock/ThirdPartyStockSlice';
import ResponseModal from '../Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';

const AddAssignee = () => {
  const { alertType, alertText, isLoading } = useSelector((state) => state.ThirdPartyStock);

  const dispatch = useDispatch();

  const [assignee, setAssignee] = useState('');

  const ModalCloseHandler = () => {
    dispatch(hideAlert());
  };

  const ConfirmbuttonHandler = (e) => {
    e.preventDefault();
    if (!assignee) {
      return dispatch(displayAlert());
    }

    dispatch(registerThirdParty({ assignee: assignee }));
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />

      <div>
        <label className="block mb-2">Assignee Name</label>
        <input
          className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
          type="text"
          value={assignee}
          onChange={(e) => setAssignee(e.target.value)}
        />
      </div>

      <br />

      <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={ConfirmbuttonHandler}>
        {isLoading ? (
          <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
        ) : (
          'Add New Assignee'
        )}
      </button>
    </>
  );
};

export default AddAssignee;
