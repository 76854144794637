import React from 'react';
import AddServiceVehicle from './AddServiceVehicle';
import ReturnProduct from './ReturnProduct';
import ServiceVehicles from './ServiceVehicles';

const ModalContentVehicleStock = ({ data, title, closeModalHandler }) => {
  let formTitle = '';

  switch (title) {
    case 'Add a Service Vehicle':
      formTitle = 'Add a Service Vehicle';
      break;

    case 'Return Product to main stock':
      formTitle = 'Return Product to main stock';
      break;

    case 'Service Vehicles':
      formTitle = 'Service Vehicles';
      break;

    default:
      formTitle = 'Add a Job Note';
      break;
  }

  return (
    <>
      <h2 className="text-center font-bold text-2xl">{formTitle}</h2>
      <form className="mx-6 space-y-3 my-5">
        {title === 'Add a Service Vehicle' && <AddServiceVehicle data={data} />}
        {title === 'Return Product to main stock' && <ReturnProduct data={data} />}
        {title === 'Service Vehicles' && <ServiceVehicles data={data} />}
      </form>
    </>
  );
};

export default ModalContentVehicleStock;
