import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addJob, displayAlert, hideAlert } from '../../features/ThirdPartyStock/ThirdPartyStockSlice';
import ResponseModal from '../Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';

const AddJobNote = ({ data }) => {
  const { id, third_party_assignee, assigneeId, product, quantity } = data;

  const { alertType, alertText, isLoading } = useSelector((state) => state.ThirdPartyStock);

  const dispatch = useDispatch();

  const [jobDetails, setJobDetails] = useState({ description: '', jobNoteNumber: '', jobQuantity: '', JobDate: '' });

  const { description, jobNoteNumber, jobQuantity, JobDate } = jobDetails;

  const ModalCloseHandler = () => {
    dispatch(hideAlert());
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;

    setJobDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ConfirmbuttonHandler = (e) => {
    e.preventDefault();
    if (!id || !assigneeId || !product.id || !jobQuantity || !description || !JobDate) {
      return dispatch(displayAlert());
    }
    dispatch(addJob({ third_party_stock_Id: id, productId: product.id, assigneeId, jobNoteNumber, description, jobQuantity, JobDate }));
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />

      <div>
        <label className="block mb-2">Product Name</label>
        <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={product.productName} readOnly={true} />
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className="w-3/5">
          <label className="block mb-2">Assignee</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            value={third_party_assignee.name}
            readOnly={true}
          />
        </div>

        <div className=" w-2/5">
          <label className="block mb-2">Company Code</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={product.companyCode} readOnly={true} />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Remaining Quantity</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={quantity} readOnly={true} />
        </div>
        <div className=" w-3/5">
          <label className="block mb-2"> Service Job Quantity</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="jobQuantity"
            value={jobQuantity}
            onChange={inputHandler}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Job Note Number</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="jobNoteNumber"
            value={jobNoteNumber}
            onChange={inputHandler}
          />
        </div>
        <div className=" w-2/5">
          <label className="block mb-2">Job Date</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            name="JobDate"
            type="date"
            value={JobDate}
            onChange={inputHandler}
          />
        </div>
      </div>

      <div className=" w-full">
        <label className="block mb-2">description</label>
        <textarea
          className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
          name="description"
          type="text"
          value={description}
          onChange={inputHandler}
        />
      </div>

      <br />

      <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={ConfirmbuttonHandler}>
        {isLoading ? (
          <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
        ) : (
          'Confirm'
        )}
      </button>
    </>
  );
};

export default AddJobNote;
