import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFillTrashFill, BsPlusCircleFill } from 'react-icons/bs';
import ResponseModal from '../../components/Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';
import { generatallPDF } from '../../utils/InvoicePDF';
import axiosInstance from '../../utils/AuthFetch';
import { BASE_URL } from '../../constants';
import { addJob, getThirdPartyStockByAssigneeId, displayAlert, hideAlert } from '../../features/ThirdPartyStock/ThirdPartyStockSlice';

//add job notes for service vehicel

const AddJobNoteThirdPartyAgent = () => {
  const [searchText, setSearchText] = useState('');
  const [products, setproducts] = useState([]);
  const [thirdpartyassignees, setThirdpartyassignees] = useState([]);
  const [selectedThirdpartyassigneeValue, setSelectedThirdpartyassigneeValue] = useState('');

  const [jobDetails, setJobDetails] = useState({
    jobNoteNumber: '',
    jobDate: '',
    description: '',
  });

  const dispatch = useDispatch();
  const { ThirdPartyStock, isLoading, alertType, alertText } = useSelector((state) => state.ThirdPartyStock);

  const buttonHandler = (product) => {
    if (!products.filter((item) => item.id === product.id).length > 0) {
      setproducts((prevProducts) => [...prevProducts, { ...product, enteredQuantity: 0 }]);
      setSearchText('');
    }
  };

  const removeProductHandler = (productId) => {
    setproducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));
  };

  const calculateTotalPrice = () => {
    return Object.values(products).reduce((sum, product) => {
      if (product.enteredQuantity <= product.quantity) {
        return sum + product.enteredQuantity * product.sellingPrice;
      }
      return sum;
    }, 0);
  };

  const inputHandler = (productId, quantity) => {
    setproducts((prevProducts) => prevProducts.map((product) => (product.id === productId ? { ...product, enteredQuantity: quantity } : product)));
  };

  //confirm button visibility
  const areAllItemsConfirmed = (products) => {
    return products.length > 0 && products.every((product) => product.enteredQuantity > 0 && product.quantity >= product.enteredQuantity);
  };

  const confirmButtonHandler = () => {
    const productQuntity = areAllItemsConfirmed(products);
    const thirdPartyID = thirdpartyassignees.find((item) => item.name === selectedThirdpartyassigneeValue);

    const { description, jobNoteNumber, jobDate } = jobDetails;

    if (!description || !jobNoteNumber || !jobDate) {
      return dispatch(displayAlert({ alertText: 'Please provide all values', alertType: 'error' }));
    }

    if (!productQuntity) {
      return dispatch(
        displayAlert({ alertText: 'Entered quantity is greater than available quantity or not enterd the quantity.', alertType: 'error' })
      );
    }

    dispatch(
      addJob({
        ...jobDetails,
        assigneeId: thirdPartyID.id,
        jobProducts: products.map((product) => ({ productId: product.productId, quantity: product.enteredQuantity })),
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    setSelectedThirdpartyassigneeValue(e.target.value);
  };

  const resetButtonHandler = () => {
    setSelectedThirdpartyassigneeValue('');
    setJobDetails({ customerName: '', customerAddress: '', customerPhone: '', jobNoteNumber: '' });
    setproducts([]);
  };

  const getthirdpartyassignees = async () => {
    const response = await axiosInstance.get(`${BASE_URL}thirdpartystock/assignee`);
    setThirdpartyassignees(response.data);
  };

  useEffect(() => {
    getthirdpartyassignees();
  }, []);

  useEffect(() => {
    const thirdPartyID = thirdpartyassignees.find((item) => item.name === selectedThirdpartyassigneeValue);
    dispatch(getThirdPartyStockByAssigneeId({ thirdPartyID: thirdPartyID?.id, searchText }));
  }, [searchText]);

  const totalPrice = calculateTotalPrice();

  const ModalCloseHandler = () => {
    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <div className=" max-w-7xl flex justify-center flex-col mx-auto relative">
      <h2 className="text-xs sm:text-lg md:text-xl lg:text-xl font-semibold text-center mb-3">Add Job Note (Third Party)</h2>

      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />
      <label className="block mb-2">Select Third Party Assignee</label>
      <div className="flex flex-row items-center w-full justify-between mb-5">
        <div className="w-4/5">
          <select
            className="border border-gray-300 w-full p-2 rounded-l-lg"
            id="dropdown"
            value={selectedThirdpartyassigneeValue}
            onChange={handleSelectChange}
            disabled={selectedThirdpartyassigneeValue}
          >
            <option value="" className="text-center">
              Select an Assignee
            </option>
            {thirdpartyassignees?.map((item) => {
              return (
                <option key={item.id} value={item.name} className="text-center">
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <button className="bg-blue-700 text-white  font-bold w-1/5 p-2 rounded-r-md" onClick={resetButtonHandler}>
          Reset
        </button>
      </div>

      <h2>Product Search</h2>
      <div className="w-full relative">
        <input
          className="w-full p-2 rounded-md border border-gray-300 mb-5"
          type="text"
          value={searchText}
          onChange={(text) => setSearchText(text.target.value)}
          placeholder="Search for products..."
          disabled={!selectedThirdpartyassigneeValue}
        />

        {searchText && ThirdPartyStock.length > 0 && (
          <ul className="bg-zinc-400 p-2 w-full overflow-y-scroll absolute text-center rounded-md z-10 max-h-40">
            {ThirdPartyStock?.map((item) => (
              <li className="" key={item.id}>
                <button onClick={() => buttonHandler(item)} className="text-black font-semibold">
                  {item.product.productName}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="flex flex-row justify-between gap-5 mb-5">
        <div className="w-3/5">
          <label className="block mb-2">Job Note Number</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="jobNoteNumber"
            value={jobDetails.jobNoteNumber}
            onChange={handleChange}
          />
        </div>

        <div className="w-3/5">
          <label className="block mb-2">Job Date</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="date"
            name="jobDate"
            value={jobDetails.jobDate}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className=" w-full">
        <label className="block mb-2">description</label>
        <textarea
          className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
          name="description"
          type="text"
          value={jobDetails.description}
          onChange={handleChange}
        />
      </div>

      <div className="flex flex-row justify-end gap-x-4 mt-3">
        <button
          className={`text-white font-bold self-end w-44 p-2 bg-blue-700 rounded-md mb-2 ${products.length === 0 && 'opacity-50 cursor-not-allowed'}`}
          disabled={isLoading || products.length === 0}
          onClick={confirmButtonHandler}
        >
          {isLoading ? (
            <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={5} aria-label="Loading Spinner" data-testid="loader" />
          ) : (
            'Confirm Job Note'
          )}
        </button>

        {/* <button
          className="text-white self-end p-2 bg-blue-700 rounded-md mb-2"
          disabled={isLoading}
          onClick={() => generatallPDF({ jobDetails, products, totalPrice })}
        >
          pdf
        </button> */}
      </div>

      <table className="table-auto w-full z-1">
        <thead className="bg-black sticky top-0">
          <tr>
            <th className="text-white  px-4 py-3 text-left">Product Name</th>
            <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Available Quntity</th>
            <th className="text-white  px-4 py-3 text-left">Job Quantity</th>
            <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(products).map((product) => {
            const isQuantityExceeded = product.enteredQuantity > product.quantity;
            return (
              <tr className={`odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800 `} key={product.id}>
                <td className={`text-gray-950 p-5 ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>{product.product.productName}</td>
                <td className={`text-gray-950 p-5 ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>{product.quantity}</td>
                <td className={`text-gray-950 p-5 hidden sm:table-cell ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>
                  <input
                    className="border border-black rounded-md"
                    type="text"
                    onChange={(e) => inputHandler(product.id, parseInt(e.target.value) || 0)}
                    placeholder="Enter quantity..."
                  />
                </td>
                <td className="text-blue-700 p-5 flex flex-row items-center cursor-pointer">
                  <BsFillTrashFill size={20} title="Delete" onClick={() => removeProductHandler(product.id)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AddJobNoteThirdPartyAgent;
