import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFillTrashFill, BsPlusCircleFill, BsPrinterFill } from 'react-icons/bs';
import ResponseModal from '../../components/Modals/ResponseModal';
import { getProductsForSearch, createCustomerInovice, displayAlert, hideAlert } from '../../features/Product/CustomerInvoice';
import PropagateLoader from 'react-spinners/PulseLoader';
import { generatallPDF } from '../../utils/InvoicePDF';

const AddCustomerInvoice = () => {
  const [searchText, setSearchText] = useState('');
  const [products, setproducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [customerDetails, setCustomerDetails] = useState({
    customerName: '',
    customerAddress: '',
    customerPhone: '',
    discount: '',
    customerREFNo: '',
    SKREFNo: '',
    invoiceNo: '',
  });

  const isAllItemsFilled = Object.keys(customerDetails)
    .filter((key) => key !== 'discount')
    .every((key) => !!customerDetails[key]);

  console.log('isAll', isAllItemsFilled);

  const dispatch = useDispatch();
  const { searchProducts, isLoading, alertType, alertText } = useSelector((state) => state.CustomerInvoice);

  const buttonHandler = (product) => {
    if (!products.filter((item) => item.id === product.id).length > 0) {
      setproducts((prevProducts) => [...prevProducts, { ...product, enteredQuantity: 0 }]);
      setErrorMessage('');
      setSearchText('');
    }
  };

  const removeProductHandler = (productId) => {
    setproducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));
  };
  const calculateTotalPrice = () => {
    const { discount } = customerDetails;
    return (
      Object.values(products).reduce((sum, product) => {
        if (product.enteredQuantity <= product.quantity) {
          return sum + product.enteredQuantity * product.sellingPrice;
        }
        return sum;
      }, 0) - Number(discount)
    );
  };
  const validateAndAddQuantity = (product, pID) => {
    const selectedProduct = products[pID];
    if (!selectedProduct) {
      // setErrorMessage('Please select a product before adding quantity.');
      return dispatch(displayAlert({ alertText: 'Please select a product before adding quantity', alertType: 'error' }));
    }

    const { enteredQuantity, quantity } = selectedProduct;

    if (enteredQuantity > quantity) {
      // setErrorMessage('Entered quantity is greater than available quantity.');
      return dispatch(displayAlert({ alertText: 'Entered quantity is greater than available quantity', alertType: 'error' }));
    } else {
      setErrorMessage('');
    }
  };

  const inputHandler = (productId, quantity) => {
    setproducts((prevProducts) => prevProducts.map((product) => (product.id === productId ? { ...product, enteredQuantity: quantity } : product)));
  };

  //confirm button visibility
  const areAllItemsConfirmed = (products) => {
    return products.length > 0 && products.every((product) => product.enteredQuantity > 0 && product.quantity >= product.enteredQuantity);
  };

  //vat calculation for bill
  const totalPrice = calculateTotalPrice();
  const vatValue = (totalPrice * 18) / 100;
  const netTotal = totalPrice + vatValue;

  const confirmButtonHandler = () => {
    const productQuntity = areAllItemsConfirmed(products);
    if (!productQuntity) {
      // setErrorMessage('Entered quantity is greater than available quantity or not enterd customer quantity');
      return dispatch(
        displayAlert({ alertText: 'Entered quantity is greater than available quantity or not enterd customer quantity', alertType: 'error' })
      );
    }

    dispatch(
      createCustomerInovice({
        ...customerDetails,
        total: totalPrice,
        vatAmount: vatValue,
        netTotal: netTotal,
        selectedProducts: products.map((product) => ({ productId: product.id, quantity: product.enteredQuantity })),
      })
    );
  };

  // const errorModalCloseHandler = () => {
  //   setErrorMessage('');
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(getProductsForSearch(searchText));
  }, [searchText]);

  useEffect(() => {
    if (products.length === 0) {
      setCustomerDetails((prevState) => ({
        ...prevState,
        discount: 0,
      }));
    }
  }, [products]);

  const formRest = () => {
    setCustomerDetails({
      customerName: '',
      customerAddress: '',
      customerPhone: '',
      discount: '',
      customerREFNo: '',
      SKREFNo: '',
      invoiceNo: '',
    });
    setproducts([]);
  };

  const ModalCloseHandler = () => {
    if (alertType === 'success') {
      formRest();
    }

    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  // const productQuntity = areAllItemsConfirmed(products);

  const buttonVisibility = products?.length > 0 && isAllItemsFilled;

  console.log('====================================');
  console.log('products?.length', products?.length);
  console.log('isAllItemsFilled', isAllItemsFilled);
  console.log('isAllItemsFilled', products);
  console.log('isAllItemsFilled', customerDetails);
  console.log('====================================');

  return (
    <div className=" max-w-7xl flex justify-center flex-col mx-auto relative">
      <div className="bg-gray-200 p-2 rounded-lg mb-2">
        <h2 className="text-xs sm:text-lg md:text-xl lg:text-xl font-semibold text-center">Add Customer Invoice</h2>
        <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />
        <h2>Product Search</h2>
        <div className="w-full relative">
          <input
            className="w-full p-2 rounded-md border border-gray-300 mb-5"
            type="text"
            value={searchText}
            onChange={(text) => setSearchText(text.target.value)}
            placeholder="Search for products..."
          />

          {searchText && searchProducts.length > 0 && (
            <ul className="bg-blue-700 p-2 w-full overflow-y-scroll absolute text-center rounded-md z-10 max-h-40">
              {searchProducts.map((product) => (
                <li className="" key={product.id}>
                  <button onClick={() => buttonHandler(product)} className="text-white font-semibold">
                    {product.productName} - Rs.{product.sellingPrice}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-5 mb-5">
          <div className="w-full md:-3/5">
            <label className="block mb-2">Customer Name</label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="customerName"
              value={customerDetails.customerName}
              onChange={handleChange}
            />
          </div>

          <div className="w-full md:-3/5">
            <label className="block mb-2">Location</label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="customerAddress"
              value={customerDetails.customerAddress}
              onChange={handleChange}
            />
          </div>

          <div className="w-full md:-3/5">
            <label className="block mb-2">Phone Number</label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="customerPhone"
              value={customerDetails.customerPhone}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-5 mb-5">
          <div className="w-full md:-3/5">
            <label className="block mb-2">Invoice No</label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="invoiceNo"
              value={customerDetails.invoiceNo}
              onChange={handleChange}
            />
          </div>

          <div className="w-full md:-3/5">
            <label className="block mb-2">Customer REF NO/S </label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="customerREFNo"
              value={customerDetails.customerREFNo}
              onChange={handleChange}
            />
          </div>

          <div className="w-full md:-3/5">
            <label className="block mb-2">SK REF NO/S </label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="SKREFNo"
              value={customerDetails.SKREFNo}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-5 mb-5 items-center ">
          <div className="w-full md:3/5">
            <label className="block mb-2">Discount</label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="discount"
              value={customerDetails.discount}
              onChange={handleChange}
              // onChange={(text) => setDiscount(text.target.value)}
            />
          </div>

          <div className="w-full md:w3/5">
            <label className="block mb-2">Total</label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="total"
              value={totalPrice}
              disabled={true}
            />
          </div>

          <div className="w-full md:w3/5">
            <label className="block mb-2">Vat 18%</label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="total"
              value={vatValue}
              disabled={true}
            />
          </div>

          <div className="w-full md:w3/5">
            <label className="block mb-2">Net Total</label>
            <input
              className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
              type="text"
              name="total"
              value={netTotal}
              disabled={true}
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center sm:justify-end items-center gap-5 ">
          <div className=" flex flex-row gap-x-2 items-center">
            <label className="block  text-lg ">Quotation status</label>
            <input className="block  rounded-lg  bg-gray-50 border border-gray-300 " type="checkbox" name="total" value={totalPrice} />
          </div>

          <button
            className={`text-white sm:self-end w-32  p-2 bg-blue-700 rounded-md ${!buttonVisibility && 'opacity-50 cursor-not-allowed'}`}
            disabled={isLoading || !buttonVisibility}
            onClick={confirmButtonHandler}
          >
            {isLoading ? (
              <PropagateLoader
                color={'white'}
                loading={isLoading}
                cssOverride={override}
                size={5}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              'Confirm Invoice'
            )}
          </button>

          <div>
            <button
              className={`text-white  p-2 bg-blue-700 rounded-md ${!buttonVisibility && `opacity-50 cursor-not-allowed`}`}
              title="Export PDF"
              disabled={isLoading || !buttonVisibility}
              onClick={() => generatallPDF({ customerDetails, products, totalPrice })}
            >
              <BsPrinterFill size={24} />
            </button>
          </div>
        </div>
      </div>

      <table className="table-auto w-full">
        <thead className="bg-black sticky top-0">
          <tr>
            <th className="text-white  px-4 py-3 text-left">Product Name</th>
            <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Available Quntity</th>
            <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Cost</th>
            <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Selling Price</th>
            <th className="text-white  px-4 py-3 text-left">Customer Quantity</th>
            <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(products).map((product) => {
            const isQuantityExceeded = product.enteredQuantity > product.quantity;
            return (
              <tr className={`odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800 `} key={product.id}>
                <td className={`text-gray-950 p-5 ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>{product.productName}</td>
                <td className={`text-gray-950 p-5 ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>{product.quantity}</td>
                <td className={`text-gray-950 p-5 hidden sm:table-cell ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>
                  {product.unitCost}
                </td>
                <td className={`text-gray-950 p-5 hidden sm:table-cell ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>
                  {product.sellingPrice}
                </td>
                <td className={`text-gray-950 p-5 hidden sm:table-cell ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>
                  <input
                    className="border border-black rounded-md"
                    type="text"
                    onChange={(e) => inputHandler(product.id, parseInt(e.target.value) || 0)}
                    placeholder="Enter quantity..."
                  />
                </td>
                <td className="text-blue-700 p-5 flex flex-row items-center cursor-pointer">
                  <BsPlusCircleFill
                    className="mr-3"
                    size={20}
                    title="Update quantity"
                    name="Update quantity"
                    onClick={() => validateAndAddQuantity(product, product.id)}
                  />
                  <BsFillTrashFill size={20} title="Delete" onClick={() => removeProductHandler(product.id)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AddCustomerInvoice;
