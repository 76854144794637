import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../utils/AuthFetch';
import { BASE_URL } from '../../constants';

const initialState = {
  isLoading: false,
  errorMsg: 'is currently empty',
  alertType: '',
  alertText: '',
  directJobNoteCustomerDetails: [],
  directJobNoteCustomerProducts: [],
  searchProducts: [],
  numOfPages: 1,
  page: 1,
};

export const createCustomerDirectJobNote = createAsyncThunk('DirectJobNote/createCustomerDirectJobNote', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}product/addDirectJobNote`, data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const getCustomerDirectJobNotes = createAsyncThunk('DirectJobNote/getCustomerDirectJobNotes', async (searchText, thunkAPI) => {
  try {
    const { page } = thunkAPI.getState().DirectJobNote;
    let pagePram = page;

    const resp = await axiosInstance.get(`product/getDirectJobNote?searchText=${searchText}&page=${pagePram}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const getCustomerDirectJobNotesProducts = createAsyncThunk('DirectJobNote/getCustomerDirectJobNotesProducts', async (jobNumber, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/getDirectJobNoteProducts?jobNumber=${jobNumber}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note
export const getProductsForSearch = createAsyncThunk('DirectJobNote/getProductsForSearch', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/directJobNote?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

const DirectJobNoteSlice = createSlice({
  name: 'DirectJobNote',
  initialState,
  reducers: {
    displayAlert: (state, { payload }) => {
      state.showAlert = true;
      state.alertType = payload ? payload.alertType : 'error';
      state.alertText = payload ? payload.alertText : 'Please provide all values';
    },
    hideAlert: (state) => {
      state.showAlert = false;
      state.alertType = '';
      state.alertText = '';
    },
  },

  extraReducers: (builder) => {
    builder
      //createCustomerDirectJobNote
      .addCase(createCustomerDirectJobNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCustomerDirectJobNote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'Jobe Note Added Successfully';
        // state.MainStockItems = action.payload.products;
      })
      .addCase(createCustomerDirectJobNote.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getCustomerDirectJobNote
      .addCase(getCustomerDirectJobNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerDirectJobNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.directJobNoteCustomerDetails = action.payload;
      })
      .addCase(getCustomerDirectJobNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getCustomerDirectJobNotesProducts
      .addCase(getCustomerDirectJobNotesProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerDirectJobNotesProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.directJobNoteCustomerProducts = action.payload;
      })
      .addCase(getCustomerDirectJobNotesProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getProductsForSearch
      .addCase(getProductsForSearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsForSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchProducts = action.payload;
      })
      .addCase(getProductsForSearch.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      });
  },
});
export const { displayAlert, hideAlert, isLoading } = DirectJobNoteSlice.actions;

export default DirectJobNoteSlice.reducer;
