import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct, hideAlert, displayAlert } from '../../features/Product/MainStockSlice';
import ResponseModal from '../Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';

const AddProduct = () => {
  const dispatch = useDispatch();
  const { isLoading, alertType, alertText } = useSelector((state) => state.MainStock);

  const [formData, setFormData] = useState({
    productName: '',
    companyCode: '',
    bin: '',
    supplier: '',
    category: '',
    unitCost: '',
    sellingPrice: '',
    quantity: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const formRest = () => {
    setFormData({
      productName: '',
      companyCode: '',
      bin: '',
      supplier: '',
      category: '',
      unitCost: '',
      sellingPrice: '',
      quantity: '',
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const { productName, companyCode, bin, supplier, category, unitCost, sellingPrice, quantity } = formData;

    const quantityValue = Number(quantity);

    if (!productName || !companyCode || !bin || !supplier || !category || !unitCost || !sellingPrice || !quantity) {
      return dispatch(displayAlert());
    }

    if (quantity <= 0 || isNaN(quantity)) {
      return dispatch(displayAlert({ alertText: 'Please enter a valid quantity.', alertType: 'error' }));
    }

    if ((sellingPrice <= 0) | isNaN(sellingPrice)) {
      return dispatch(displayAlert({ alertText: 'Please enter a valid selling price.', alertType: 'error' }));
    }

    if (unitCost <= 0 || isNaN(unitCost)) {
      return dispatch(displayAlert({ alertText: 'Please enter a valid unit cost.', alertType: 'error' }));
    }

    dispatch(addProduct({ ...formData, quantity: quantityValue }));
  };

  const ModalCloseHandler = () => {
    if (alertType === 'success') {
      formRest();
    }
    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />
      <div>
        <label className="block mb-2">Product Name</label>
        <input
          className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
          type="text"
          name="productName"
          value={formData.productName}
          onChange={handleInputChange}
        />
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Company Code</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="companyCode"
            value={formData.companyCode}
            onChange={handleInputChange}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Bin</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="bin"
            value={formData.bin}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div>
        <label className="block mb-2">Supplier</label>
        <input
          className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
          type="text"
          name="supplier"
          value={formData.supplier}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label className="block mb-2">Category</label>
        <input
          className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
          type="text"
          name="category"
          value={formData.category}
          onChange={handleInputChange}
        />
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Unit Cost (Rs)</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="unitCost"
            value={formData.unitCost}
            onChange={handleInputChange}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Selling Price (Rs)</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="sellingPrice"
            value={formData.sellingPrice}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div>
        <label className="block mb-2">Quantity</label>
        <input
          className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
          type="text"
          name="quantity"
          value={formData.quantity}
          onChange={handleInputChange}
        />
      </div>

      <br />

      <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={submitHandler} disabled={isLoading}>
        {isLoading ? (
          <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
        ) : (
          'Add Product'
        )}
      </button>
    </>
  );
};

export default AddProduct;
