import React from 'react';
import { useSelector } from 'react-redux';
import PropagateLoader from 'react-spinners/PulseLoader';

const DirectJobNoteCustomerProductDetails = () => {
  const { directJobNoteCustomerProducts, isLoading } = useSelector((state) => state.DirectJobNote);

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <div className="relative overflow-auto max-h-96">
      {isLoading ? (
        <div className="flex justify-center">
          <PropagateLoader color={'red'} loading={isLoading} cssOverride={override} size={12} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <table className="table-auto w-full">
          <thead className="bg-black ">
            <tr>
              <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Job ID</th>
              <th className="sticky top-0 z-20 text-white px-4 py-3 text-left bg-black ">Product Name</th>
              <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Job Quantity</th>
            </tr>
          </thead>
          <tbody>
            {directJobNoteCustomerProducts.map((item) => (
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800" key={item.id}>
                <td className="text-gray-950 p-5 ">{item.directJobnoteId}</td>
                <td className="text-gray-950 p-5  ">{item.product.productName}</td>
                <td className="text-gray-950 p-5 ">{item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DirectJobNoteCustomerProductDetails;
