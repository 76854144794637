import React from 'react';

const JobNotesModal = ({ open, closeHandler, children }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="fixed z-20 inset-0  bg-black bg-opacity-75 backdrop-blur-sm flex justify-center items-center text-slate-950">
      <div className="w-[500px] sm:w-[600px] md:w-full flex flex-col p-16 max-h-screen">
        <button className="text-white text-xl place-self-end" onClick={() => closeHandler()}>
          X
        </button>
        <div className="bg-white p-2 rounded overflow-y-auto max-h-screen">{children}</div>
      </div>
    </div>
  );
};

export default JobNotesModal;
