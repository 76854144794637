import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { displayAlert, hideAlert, addProductToServiceVehicle } from '../../features/ServiceVehicle/ServiceVehicleSlice';
import PropagateLoader from 'react-spinners/PulseLoader';
import ResponseModal from '../Modals/ResponseModal';
import axiosInstance from '../../utils/AuthFetch';

const ServiceVehicle = ({ data, closeModalHandler }) => {
  const { bin, companyCode, id, productName, quantity, supplier } = data;

  const dispatch = useDispatch();
  const { alertType, alertText, isLoading } = useSelector((state) => state.ServiceVehicle);

  const [serviceVehiclesDetails, setServiceVehiclesDetails] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedQuntity, setSelectedQuntity] = useState(0);

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const getServiceVehicles = async () => {
    const response = await axiosInstance.get(`${BASE_URL}servicevehicle`);
    setServiceVehiclesDetails(response.data);
  };

  const quntityHandler = (e) => {
    setSelectedQuntity(Number(e.target.value));
  };

  const buttonHandler = (e) => {
    e.preventDefault();
    const serviceVehiclId = selectedValue.slice(0, 1);

    if (!serviceVehiclId || !id || !selectedQuntity) {
      return dispatch(displayAlert());
    }

    if (isNaN(selectedQuntity) || selectedQuntity <= 0) {
      return dispatch(displayAlert({ alertText: 'Please enter a valid quantity.', alertType: 'error' }));
    }

    dispatch(addProductToServiceVehicle({ service_vehicle_Id: Number(serviceVehiclId), productId: id, quantity: selectedQuntity }));
  };

  const ModalCloseHandler = () => {
    if (alertType === 'success') {
      closeModalHandler();
    }
    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  useEffect(() => {
    getServiceVehicles();
  }, []);

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />
      <div className="flex flex-row justify-between gap-5">
        {/* <div className="w-2/5">
          <label className="block mb-2">Product ID</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={id} readOnly={true} />
        </div> */}
        <div className="w-4/5">
          <label className="block mb-2">Product Name</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={productName} readOnly={true} />
        </div>

        <div className="w-2/5">
          <label className="block mb-2">Available Quantity</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={quantity} readOnly={true} />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Company Code</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={companyCode} readOnly={true} />
        </div>

        {/* <div className=" w-3/5">
          <label className="block mb-2">Bin</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={bin} readOnly={true} />
        </div> */}

        <div className=" w-3/5">
          <label className="block mb-2">Supplier</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={supplier} readOnly={true} />
        </div>
      </div>

      <div>
        <label className="block mb-2">Available Quantity</label>
        <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={quantity} readOnly={true} />
      </div>

      <div>
        <label className="block mb-2">Quantity</label>
        <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" onChange={quntityHandler} />
      </div>

      <div>
        <label className="block mb-2">Select Service Vehicle</label>
        <select className="border border-gray-300 w-full p-2 rounded-lg" id="dropdown" value={selectedValue} onChange={handleSelectChange}>
          <option value="" className="text-center">
            Select a Service Vehicle
          </option>
          {serviceVehiclesDetails?.map((item) => {
            return (
              <option key={item.id} value={`${item.id}-${item.vehicleNumber}`} className="text-center">
                {item.vehicleNumber} | {item.type}
              </option>
            );
          })}
        </select>
      </div>

      <br />

      <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={buttonHandler} disabled={isLoading}>
        {isLoading ? (
          <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
        ) : (
          'Add To Service Vehicle'
        )}
      </button>
    </>
  );
};

export default ServiceVehicle;
