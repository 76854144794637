import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, hideAlert, displayAlet } from '../features/user/AuthSlice';
import PropagateLoader from 'react-spinners/PulseLoader';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const { isLoading, user, showAlert, alertText, alertType } = useSelector((state) => state.Auth);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = credentials;
    if (!email || !password) {
      dispatch(displayAlet());
      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
      return;
    }
    setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
    dispatch(login(credentials));
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
  }, [user, navigate]);

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <div className="flex items-center justify-center overflow-auto h-screen bg-slate-600">
      <form className="flex flex-col border items-center bg-white p-6 rounded-sm w-1/5 min-w-[250px] sm:min-w-[400px]" onSubmit={handleSubmit}>
        {showAlert && (
          <h1 className={`text-white ${alertType === 'error' ? 'bg-red-500' : 'bg-green-600'} p-2 w-full rounded-lg text-center mb-2`}>
            {alertText}
          </h1>
        )}
        <div className="mb-4 w-full">
          <label htmlFor="email" className="block mb-2">
            Email
          </label>
          <input
            id="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            className="p-2 rounded-lg w-full bg-gray-50 border border-gray-300"
            type="text"
          />
        </div>

        <div className="mb-4 w-full">
          <label htmlFor="password" className="block mb-2">
            Password
          </label>
          <div className="flex justify-end items-center relative">
            <input
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              className="p-2 rounded-lg w-full bg-gray-50 border border-gray-300"
              type={showPassword ? 'text' : 'password'}
            />
            {showPassword ? (
              <AiFillEye className="absolute mr-2 w-10 cursor-pointer" onClick={togglePasswordVisibility} />
            ) : (
              <AiFillEyeInvisible className="absolute mr-2 w-10 cursor-pointer" onClick={togglePasswordVisibility} />
            )}
          </div>
        </div>

        <button type="submit" className="bg-blue-700 p-2 rounded-md text-white w-full" disabled={isLoading}>
          {isLoading ? (
            <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={5} aria-label="Loading Spinner" data-testid="loader" />
          ) : (
            'Log In'
          )}
        </button>
        <button className="p-2 rounded-md self-end">Forgot Password?</button>
      </form>
    </div>
  );
};

export default SignIn;
