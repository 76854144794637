import React, { useState } from 'react';
import { editProduct, displayAlert, hideAlert } from '../../features/ColomboShowroom/ColomboShowroomStockSlice';
import { useSelector, useDispatch } from 'react-redux';
import ResponseModal from '../Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';

const Edit = ({ data }) => {
  const { id, bin, productName, category, supplier, companyCode, sellingPrice, unitCost, quantity } = data;

  const dispatch = useDispatch();
  const { alertType, alertText, isLoading } = useSelector((state) => state.ColomboShowroomStock);

  const [productDetails, setProductDetails] = useState({
    bin,
    productName,
    category,
    supplier,
    companyCode,
    unitCost,
    sellingPrice,
  });

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sumbitHandler = (e) => {
    e.preventDefault();
    const { bin, productName, category, supplier, companyCode } = productDetails;
    if (!bin || !productName || !category || !supplier || !companyCode || !sellingPrice || !unitCost) {
      return dispatch(displayAlert());
    }
    dispatch(editProduct({ ...productDetails, id }));
  };

  const ModalCloseHandler = () => {
    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />

      <div className="flex flex-row justify-between gap-5">
        <div className="w-2/5">
          <label className="block mb-2">Product ID</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={id} readOnly={true} />
        </div>

        <div className="w-4/5">
          <label className="block mb-2">Product Name</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="productName"
            value={productDetails.productName}
            onChange={inputHandler}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Company Code</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="companyCode"
            value={productDetails.companyCode}
            onChange={inputHandler}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Bin</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="bin"
            value={productDetails.bin}
            onChange={inputHandler}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Supplier</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="supplier"
            value={productDetails.supplier}
            onChange={inputHandler}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Category</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="category"
            value={productDetails.category}
            onChange={inputHandler}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Unit Cost (Rs)</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="unitCost"
            value={productDetails.unitCost}
            onChange={inputHandler}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Selling Price (Rs)</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="sellingPrice"
            value={productDetails.sellingPrice}
            onChange={inputHandler}
          />
        </div>
      </div>

      <div>
        <label className="block mb-2">Quantity</label>
        <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" readOnly={true} value={quantity} />
      </div>

      <br />

      <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={sumbitHandler}>
        {isLoading ? (
          <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
        ) : (
          ' Update Product Details'
        )}
      </button>
    </>
  );
};

export default Edit;
