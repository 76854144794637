import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../constants';
import axiosInstance from '../../utils/AuthFetch';

const initialState = {
  MainStockItems: [],
  totalProducts: 1,
  numOfPages: 1,
  page: 1,
  isLoading: false,
  errorMsg: 'is currently empty',
  showAlert: false,
  alertType: '',
  alertText: '',
};

export const getMainStockItems = createAsyncThunk('MainStock/getMainStockItems', async (searchText, thunkAPI) => {
  try {
    const { page } = thunkAPI.getState().MainStock;

    let pagePram = page;

    // if (searchText) {
    //   pagePram = 1;
    // }
    const resp = await axiosInstance.get(`product?searchText=${searchText}&page=${pagePram}`);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

export const addProduct = createAsyncThunk('MainStock/addProduct', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}product`, data);
    thunkAPI.dispatch(getMainStockItems(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const editProduct = createAsyncThunk('MainStock/editProduct', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.put(`${BASE_URL}product/${data.id}`, data);
    thunkAPI.dispatch(getMainStockItems(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

export const addQuntity = createAsyncThunk('MainStock/addQuntity', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.patch(`${BASE_URL}product/addQuntity/${data.id}`, { quantity: data.quantityValueNumber });
    thunkAPI.dispatch(getMainStockItems(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

export const deleteProduct = createAsyncThunk('MainStock/deleteProduct', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.put(`${BASE_URL}product/delete/${data.id}`);
    thunkAPI.dispatch(getMainStockItems(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

const MainStockSlice = createSlice({
  name: 'MainStock',
  initialState,
  reducers: {
    changePageNumber: (state, { payload }) => {
      state.page = payload;
    },
    displayAlert: (state, { payload }) => {
      state.showAlert = true;
      state.alertType = payload ? payload.alertType : 'error';
      state.alertText = payload ? payload.alertText : 'Please provide all values';
    },
    hideAlert: (state) => {
      state.showAlert = false;
      state.alertType = '';
      state.alertText = '';
    },
  },

  extraReducers: (builder) => {
    builder
      //get Products
      .addCase(getMainStockItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMainStockItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.MainStockItems = action.payload.products;
        state.totalProducts = action.payload.totalProducts;
        state.numOfPages = action.payload.numOfPages;
      })
      .addCase(getMainStockItems.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      //add Product
      .addCase(addProduct.pending, (state) => {
        state.isLoading = true;
        state.showAlert = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showAlert = true;
        state.alertType = 'success';
        state.alertText = 'Product Added Successfully';
        // state.MainStockItems = action.payload;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.showAlert = true;
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //update Product
      .addCase(editProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully product is updated';
      })
      .addCase(editProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //addQuntity
      .addCase(addQuntity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQuntity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully product quntity updated';
      })
      .addCase(addQuntity.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //deleteProduct
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully product is deleted';
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      });
  },
});
export const { changePageNumber, displayAlert, hideAlert } = MainStockSlice.actions;

export default MainStockSlice.reducer;
