import React from 'react';
import Edit from './Edit';
import QuantityManagement from './QuantityManagement';
import AddProduct from './AddProduct';

const ModalContent = ({ data, title, closeModalHandler }) => {
  let formTitle = '';

  switch (title) {
    case 'Update quntity':
      formTitle = 'Quantity Management';
      break;

    case 'Edit':
      formTitle = 'Update Product Details';
      break;

    case 'Add Product':
      formTitle = 'Add Product to Colombo Showroom';
      break;
  }

  return (
    <>
      <h2 className="text-center font-bold text-2xl">{formTitle}</h2>
      <form className="mx-6 space-y-3 my-5">
        {title === 'Update quntity' && <QuantityManagement data={data} />}
        {title === 'Edit' && <Edit data={data} />}
        {title === 'Add Product' && <AddProduct />}
      </form>
    </>
  );
};

export default ModalContent;
