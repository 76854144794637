import React from 'react';
import DirectJobNoteCustomerProductDetails from './DirectJobNoteCustomerProductDetails.js';
import ServiceVehicelJobNotesProductsDetails from './ServiceVehicelJobNotesProductsDetails.js';
import ThirdPartyJobNotesProductDetails from './ThirdPartyJobNotesProductDetails.js';
import CustomerInvoiceProductDetails from './CustomerInvoiceProductDetails.js';
import DirectJobNoteCustomerProductDetailsColombo from './DirectJobNoteCustomerProductDetailsColombo.js';

const ModalContent = ({ data, title, closeModalHandler }) => {
  console.log(data);
  let formTitle = '';

  switch (title) {
    case 'direct JobNote Customer':
      formTitle = `Direct Job Note no- ${data.jobNoteNumber}`;
      break;

    case 'Service Vehicel JobNotes':
      formTitle = `Service Vehicel Job Note no - ${data.jobNoteNumber}`;
      break;

    case 'Third Party Job Notes':
      formTitle = `Third Party Job Note no - ${data.jobNoteNumber}`;
      break;

    case 'Customer Invoices':
      formTitle = `Customer Invoice no - ${data.item.invoiceNo}`;
      break;

    case 'direct JobNote Customer Colombo':
      formTitle = `Direct Job Note no- ${data.jobNoteNumber}`;
      break;

    default:
      formTitle = 'direct JobNote Customer';
      break;
  }

  return (
    <>
      <h2 className="text-center font-bold text-2xl sticky top-0 z-20 text-black form">{formTitle}</h2>
      <form className="mx-6 space-y-3 my-5">
        {title === 'direct JobNote Customer' && <DirectJobNoteCustomerProductDetails jobNoteNumber={data} />}
        {title === 'direct JobNote Customer Colombo' && <DirectJobNoteCustomerProductDetailsColombo jobNoteNumber={data} />}
        {title === 'Service Vehicel JobNotes' && <ServiceVehicelJobNotesProductsDetails jobDetails={data} />}
        {title === 'Third Party Job Notes' && <ThirdPartyJobNotesProductDetails jobDetails={data} />}
        {title === 'Customer Invoices' && <CustomerInvoiceProductDetails invoiceDetails={data} />}
      </form>
    </>
  );
};

export default ModalContent;
