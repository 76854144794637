import { useState } from 'react';
import { links, mobileLinks, linksColomboShowroom, mobileLinksColomboShowroom } from '../utils/links';
import { Link } from 'react-router-dom';
import { BsBellFill, BsBoxArrowRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../features/user/AuthSlice';

const NavBar = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [userDropDownOpen, setUserDropDownOpen] = useState(false);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);

  // Group links by category
  const groupedLinks = (user.role === 'colombo showroom' ? linksColomboShowroom : links).reduce((acc, link) => {
    if (!acc[link.category]) {
      acc[link.category] = [];
    }
    acc[link.category].push(link);
    return acc;
  }, {});

  const filteredGroupedLinks = Object.fromEntries(
    Object.entries(groupedLinks).filter(([category, links]) => {
      // Check if any link in the category can be shown to the user
      return links.some((link) => {
        // If the link doesn't have roles or the user has one of the roles required for the link, keep the category
        return !link.roles || link.roles.includes(user.role);
      });
    })
  );

  const sideBarClose = () => {
    setOpen(false);
    setUserDropDownOpen(false);
  };

  return (
    <div className={`flex h-screen bg-gray-100  `}>
      {/* 
     <!-- sidebar --> */}
      <div
        className={`hidden sm:transition-all sm:flex flex-col w-64 bg-gray-800 ${
          open ? 'sm:transform sm:translate-x-0 sm:overflow-y-auto' : 'sm:transform sm:-translate-x-full'
        } sm:ease-in-out sm:duration-300 sm:fixed sm:h-screen`}
      >
        {/* <div className="flex items-center justify-center h-16 bg-gray-900 ">
          <span className="text-white font-bold uppercase">Sidebar</span>
        </div> */}
        <div className="flex flex-col flex-1 mt-12">
          {Object.entries(filteredGroupedLinks).map(([category, links]) => (
            <div key={category}>
              <h2 className="px-4 py-2 text-gray-300 font-bold">{category}</h2>
              <nav className="flex-1 px-2  bg-gray-800">
                {links
                  .filter((link) => {
                    // Filter out links based on user's role
                    if (link.roles && link.roles.includes(user.role)) {
                      return true; // Show link if the user has a role specified for it
                    } else {
                      return false; // Hide link if the user's role is not specified or doesn't match
                    }
                  })
                  .map((link) => (
                    <Link
                      to={link.path}
                      key={link.id}
                      className="flex gap-5 items-center px-4 py-2 text-gray-100 hover:bg-gray-700"
                      onClick={() => setOpen(!open)}
                    >
                      <span>{link.icon}</span>
                      {link.text}
                    </Link>
                  ))}
              </nav>
            </div>
          ))}
          {/* Logout Button */}
          {/* <button className="-bottom-0 text-gray-100 hover:bg-gray-700 px-4 py-2 gap-2 fixed flex items-center" onClick={() => dispatch(logOut())}>
            <BsBoxArrowRight size={20} />
            <span>LogOut</span>
          </button> */}
        </div>
      </div>

      {/* <!-- Main content --> */}
      <div className={`flex flex-col flex-1 overflow-y-auto ${open ? 'sm:ml-64' : ''} duration-300`}>
        <div className="flex items-center justify-between bg-white border-b border-gray-200 sticky top-0 z-10">
          <div className="flex items-center px-4  h-16 relative">
            <button className="text-gray-500 focus:outline-none focus:text-gray-700 z-10" onClick={() => setOpen(!open)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
            <h2 className=" text-gray-800 ml-5 font-bold text-xs sm:text-md md:text-xl">Sri Kanth Professional Kitchen</h2>

            {/* mobile menu */}
            {open && (
              <div className="sm:hidden fixed z-20 inset-0 bg-black bg-opacity-25  backdrop-blur-sm flex justify-center items-center text-slate-950 overflow-y-hidden">
                <div className="sm:hidden absolute w-60 h-auto bg-gray-800 top-20 py-1 rounded-lg">
                  <div>
                    <div className="text-white text-xl flex justify-end mr-3 cursor-pointer" onClick={() => setOpen(!open)}>
                      X
                    </div>
                    <nav className="flex-1 px-2  bg-gray-800">
                      {(user.role === 'colombo showroom' ? mobileLinksColomboShowroom : mobileLinks).map((link) => (
                        <Link
                          to={link.path}
                          key={link.id}
                          className="flex gap-5 items-center px-4 py-2 text-gray-100 hover:bg-gray-700"
                          onClick={() => setOpen(!open)}
                        >
                          <span>{link.icon}</span>
                          {link.text}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center pr-4">
            {/* <button class="flex items-center text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l-7-7 7-7m5 14l7-7-7-7" />
              </svg>
            </button> */}
            <div className="flex justify-between items-center gap-4">
              <div className="relative">
                <button
                  className="text-xs sm:text-md md:text-xl text-gray-800 ml-5 font-bold  capitalize"
                  onClick={() => setUserDropDownOpen(!userDropDownOpen)}
                >
                  {user.role}
                </button>
                {userDropDownOpen && (
                  <div className="absolute top-9 right-1 w-36 h-auto bg-slate-200 p-4 overflow-hidden rounded-md flex justify-center">
                    <button className="text-black flex flex-row items-center justify-center gap-x-2" onClick={() => dispatch(logOut())}>
                      <BsBoxArrowRight size={20} />
                      <span className="font-bold">LogOut</span>
                    </button>
                  </div>
                )}
              </div>

              <BsBellFill size={20} />
            </div>
          </div>
        </div>
        <div className="p-4" onClick={sideBarClose}>
          {/* <h1 class="text-2xl font-bold">Welcome to my dashboard!</h1>
          <p class="mt-2 text-gray-600">This is an example dashboard using Tailwind CSS.</p> */}
          <div className="p-4  z-0"> {children}</div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
