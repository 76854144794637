import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../constants';
import { getMainStockItems } from '../Product/MainStockSlice';
import axiosInstance from '../../utils/AuthFetch';

const initialState = {
  isLoading: false,
  errorMsg: 'is currently empty',
  showAlert: false,
  alertType: '',
  alertText: '',
  numOfPagesVehicleStock: 1,
  page: 1,
  serviceVehicleStock: [],
  serviceVehicleJobNoteProducts: [],
  serviceVehicles: [],
  jobNotes: [],
};

export const addProductToServiceVehicle = createAsyncThunk('ServiceVehicle/addProductToServiceVehicle', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}servicevehicle/product`, data);
    thunkAPI.dispatch(getMainStockItems(''));
    thunkAPI.dispatch(getServiceVehicleStock(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const getServiceVehicleStock = createAsyncThunk('ServiceVehicle/getServiceVehicleStock', async (searchText, thunkAPI) => {
  try {
    const { page } = thunkAPI.getState().ServiceVehicle;
    let pagePram = page;

    // if (searchText) {
    //   pagePram = 1;
    // }
    const resp = await axiosInstance.get(`${BASE_URL}servicevehicle/product?searchText=${searchText}&page=${page}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

export const addJob = createAsyncThunk('ServiceVehicle/addJob', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}servicevehicle/addJobNote`, data);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const getJobNotes = createAsyncThunk('ServiceVehicle/getJobNotes', async (searchText, thunkAPI) => {
  try {
    const { page } = thunkAPI.getState().ServiceVehicle;
    let pagePram = page;

    if (searchText) {
      pagePram = 1;
    }
    const resp = await axiosInstance.get(`${BASE_URL}servicevehicle/addJobNote?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

export const retrunQuantity = createAsyncThunk('ServiceVehicle/retrunQuantity', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}servicevehicle/returnProduct`, data);
    thunkAPI.dispatch(getServiceVehicleStock(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//for add jobnotes
export const getServiceVehicleStockByVehcileNumber = createAsyncThunk(
  'ServiceVehicle/getServiceVehicleStockByVehcileNumber',
  async (data, thunkAPI) => {
    try {
      const resp = await axiosInstance.get(
        `${BASE_URL}servicevehicle/vehicleStock?service_vehicle_Id=${data.service_vehicle_Id}&searchText=${data.searchText}`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

//get job notes by job number in service vehicle job notes
export const getServiceVehicleJobNoteProducts = createAsyncThunk('ServiceVehicle/getServiceVehicleJobNoteProducts', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`${BASE_URL}servicevehicle/jobNotes?jobNumber=${data}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

//add new service vehicle
export const addAServiceVehicle = createAsyncThunk('ServiceVehicle/addAServiceVehicle', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}servicevehicle`, data);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get job notes by job number in service vehicle job notes
export const getServiceVehicles = createAsyncThunk('ServiceVehicle/getServiceVehicles', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`${BASE_URL}servicevehicle`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

const ServiceVehicleSlice = createSlice({
  name: 'ServiceVehicle',
  initialState,
  reducers: {
    changePageNumber: (state, { payload }) => {
      state.page = payload;
    },
    displayAlert: (state, { payload }) => {
      state.showAlert = true;
      state.alertType = payload ? payload.alertType : 'error';
      state.alertText = payload ? payload.alertText : 'Please provide all values';
    },
    hideAlert: (state) => {
      state.showAlert = false;
      state.alertType = '';
      state.alertText = '';
    },
  },

  extraReducers: (builder) => {
    builder
      //addProductToServiceVehicle
      .addCase(addProductToServiceVehicle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProductToServiceVehicle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.showAlert = true;
        state.alertType = 'success';
        state.alertText = 'successfully product is transferred to service vehicle';
      })
      .addCase(addProductToServiceVehicle.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.showAlert = true;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      .addCase(getServiceVehicleStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServiceVehicleStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceVehicleStock = action.payload.serviceVehicleStockDetails;
        state.numOfPagesVehicleStock = action.payload.numOfPages;
      })
      .addCase(getServiceVehicleStock.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      //addJobNote
      .addCase(addJob.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully job note added';
      })
      .addCase(addJob.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getJobNotes
      .addCase(getJobNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getJobNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobNotes = action.payload;
      })
      .addCase(getJobNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //retrunQuantity
      .addCase(retrunQuantity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(retrunQuantity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully product is returned to main stock';
      })
      .addCase(retrunQuantity.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getServiceVehicleStockByVehcileNumber
      .addCase(getServiceVehicleStockByVehcileNumber.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServiceVehicleStockByVehcileNumber.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceVehicleStock = action.payload;
      })
      .addCase(getServiceVehicleStockByVehcileNumber.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getServiceVehicleJobNoteProducts
      .addCase(getServiceVehicleJobNoteProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServiceVehicleJobNoteProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceVehicleJobNoteProducts = action.payload;
      })
      .addCase(getServiceVehicleJobNoteProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //addAServiceVehicle
      .addCase(addAServiceVehicle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAServiceVehicle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully vehicle is registered';
      })
      .addCase(addAServiceVehicle.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getServiceVehicles
      .addCase(getServiceVehicles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServiceVehicles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceVehicles = action.payload;
      })
      .addCase(getServiceVehicles.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      });
  },
});
export const { changePageNumber, displayAlert, hideAlert, isLoading, alertText, showAlert } = ServiceVehicleSlice.actions;

export default ServiceVehicleSlice.reducer;
