import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const generatallPDF = async ({ customerDetails, products, totalPrice }) => {
  const pdf = new jsPDF();

  const headerBarColor = [255, 255, 255]; // RGB color for the background bar
  pdf.setFontSize(10); // Header and footer font size
  const headerText = 'SRI KANTH PROFESSIONAL KITCHEN (PVT) LTD';
  const headerSubText = 'No 06, Kotugodalla Veediya, Kandy';
  const headerSubLine = 'T . 081 2237855 | F . 081 2237620 | E . info@srikanthprofessional.com | W. www.srikanthprofessional.com';
  const footerText =
    "* Credit period 14 days.\n* Please acknowledge receipt by scanned mail to info@srikanthprofessional.com\n* This is a computer generated invoice that does not require a signature.\n* Cheques to be drawn in favor of 'Sri Kanth Professional Kitchen (Pvt) Ltd' and crossed 'Account Payee'\n";

  const currentDate = new Date();
  const yearjs = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = String(hours % 12 || 12).padStart(2, '0');

  const formattedDate = `${yearjs}-${month}-${day}  ${formattedHours}:${minutes}`;

  const topSentence = `Customer Name : ${customerDetails.customerName}\nDate : ${formattedDate} ${amPm}\nSK REF No : ${customerDetails.SKREFNo}`;
  const lines = topSentence.split('\n');
  const lineHeight = pdf.internal.getFontSize() * 0.7;

  const textSize = (pdf.getStringUnitWidth(topSentence) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor;

  pdf.setFontSize(10);

  //invoice details
  pdf.text(`Invoice No : ${customerDetails.invoiceNo}`, 130, 35);
  pdf.text(`Customer REF No : ${customerDetails.customerREFNo}`, 130, 42);
  // pdf.text(`SK REF No : ${customerDetails.SKREFNo}`, 10, 45);

  const centerX = pdf.internal.pageSize.width / 15;
  let centerY = 35;

  lines.forEach((line) => {
    const textSize = (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor;
    const y = centerY;

    pdf.text(line, centerX, y);
    centerY += lineHeight;
  });

  // Header Background Bar
  pdf.setFillColor(...headerBarColor);
  pdf.rect(3, 3, pdf.internal.pageSize.width - 2 * 3, 25, 'F');

  // Header Text
  pdf.setTextColor(0, 0, 0);
  pdf.setFont('bold');
  pdf.setFontSize(15);
  const headerWidth = (pdf.getStringUnitWidth(headerText) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor;
  const pageWidth = pdf.internal.pageSize.width;
  const headerX = (pageWidth - headerWidth) / 2;
  pdf.text(headerText, headerX + 8, 12);

  // Subtext
  pdf.setFontSize(13);
  const subtextWidth = (pdf.getStringUnitWidth(headerSubText) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor;
  const subtextX = (pageWidth - subtextWidth) / 2;
  pdf.text(headerSubText, subtextX + 8, 17); // Adjust the Y-coordinate as needed

  // SubLine
  pdf.setFontSize(12); // Set the desired smaller font size for the subtext
  const sublineWidth = (pdf.getStringUnitWidth(headerSubLine) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor;
  const sublineX = (pageWidth - sublineWidth) / 2;
  pdf.text(headerSubLine, sublineX, 24); // Adjust the Y-coordinate as needed

  // Footer Text
  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  // const footerTextHeight = pdf.getTextDimensions(footerText, { fontSize: 12 }).h;
  // const footerTextY = pdf.internal.pageSize.height - footerTextHeight - 25;
  pdf.text(footerText, 10, pdf.internal.pageSize.height - 25);

  // Define table headers and row

  // Manually draw header and border before each page
  const drawHeaderAndBorder = () => {
    // Header Background Bar
    pdf.setFillColor(...headerBarColor);
    pdf.rect(3, 3, pdf.internal.pageSize.width - 2 * 3, 25, 'F');

    // Header Text
    pdf.setTextColor(0, 0, 0);
    pdf.setFont('bold');
    pdf.setFontSize(15);
    pdf.text(headerText, headerX + 8, 12);

    // Subtext
    pdf.setFontSize(13);
    pdf.text(headerSubText, subtextX + 8, 17);

    // SubLine
    pdf.setFontSize(12);
    pdf.text(headerSubLine, sublineX, 24);

    // Footer Text
    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0);
    pdf.text(footerText, 10, pdf.internal.pageSize.height - 25);

    // Border
    pdf.setLineWidth(1);
    pdf.setDrawColor('black');
    pdf.rect(2, 2, pdf.internal.pageSize.width - 2 * 2, pdf.internal.pageSize.height - 2 * 2, 'D');
  };
  // Manually draw header and border before the loop
  drawHeaderAndBorder();

  const totalRow = {
    productName: { content: '', style: 'bold' },
    enteredQuantity: { content: '', style: 'bold' },
    sellingPrice: { content: 'Total Price', style: 'bold' },
    totalPrice: { content: `Rs.${(Number(totalPrice) + Number(customerDetails.discount)).toFixed(2)}`, style: 'bold' },
  };

  const totalRowtow = {
    productName: { content: '', style: 'bold' },
    enteredQuantity: { content: '', style: 'bold' },
    sellingPrice: { content: '18% VAT for Total Bill', style: 'bold' },
    totalPrice: { content: `Rs.${((Number(totalPrice) * 18) / 100).toFixed(2)}`, style: 'bold' },
  };

  const totalRowthreee = {
    productName: { content: '', style: 'bold' },
    enteredQuantity: { content: '', style: 'bold' },
    sellingPrice: { content: 'Total Bill with VAT', style: 'bold' },
    totalPrice: {
      content: `Rs.${(Number(totalPrice) + (Number(totalPrice).toFixed(2) * 18) / 100).toFixed(2)}`,
      style: 'bold',
    },
  };

  const total = {
    productName: { content: '', style: 'bold' },
    enteredQuantity: { content: '', style: 'bold' },
    sellingPrice: { content: 'Discount', style: 'bold' },
    totalPrice: { content: `Rs.${customerDetails.discount}`, style: 'bold' },
  };

  const totalWithDicount = {
    productName: { content: '', style: 'bold' },
    enteredQuantity: { content: '', style: 'bold' },
    sellingPrice: { content: 'Total Bill With Discount', style: 'bold' },
    totalPrice: { content: `Rs.${Number(totalPrice).toFixed(2)}`, style: 'bold' },
  };

  const emptyRow = {
    productName: { content: '', style: 'bold' },
    enteredQuantity: { content: '', style: 'bold' },
    sellingPrice: { content: '', style: 'bold' },
    totalPrice: { content: ``, style: 'bold' },
  };

  // Define table headers and rows
  const tableColumns = [
    { title: 'Product Name', dataKey: 'productName' },
    { title: 'Quantity', dataKey: 'enteredQuantity' },
    { title: 'Unit Price', dataKey: 'sellingPrice' },
    { title: 'Total Price', dataKey: 'totalPrice' },
  ];

  ///////////////////////////////////////////////////////
  let startY = 55; // or your initial starting value
  const rowHeight = 8; // adjust as needed
  const footerTextHeight = 60; // adjust as needed
  const availableSpaceForTable = pdf.internal.pageSize.height - 25 - centerY - footerTextHeight - 10;

  // Calculate the number of rows that can fit in the available space
  const rowsPerChunk = Math.floor(availableSpaceForTable / rowHeight);

  let chunkSize = rowsPerChunk;

  // // Calculate the remaining space for the first page
  // const remainingSpaceForFirstPage = availableSpaceForTable - startY;

  for (let i = 0; i < products.length; i += chunkSize) {
    // Start a new page for each iteration (except the first)
    if (i > 0) {
      pdf.addPage();
      drawHeaderAndBorder();
      startY = 30;
    }

    const endIdx = Math.min(i + chunkSize, products.length);

    const tableProducts = products.slice(i, endIdx).map((item) => ({
      productName: item.productName ? item.productName : item.product.productName,
      enteredQuantity: item.enteredQuantity ? item.enteredQuantity : item.quantity,
      sellingPrice: 'Rs.' + (item.sellingPrice ? item.sellingPrice : item.product.sellingPrice),
      totalPrice:
        'Rs.' +
        ((item.sellingPrice ? item.sellingPrice : item.product.sellingPrice) * (item.enteredQuantity ? item.enteredQuantity : item.quantity)).toFixed(
          2
        ),
    }));
    // Calculate the space needed for the table on the current page
    const tableHeight = pdf.autoTable.startY - startY;

    // If there is not enough space, adjust chunk size
    if (tableHeight > availableSpaceForTable) {
      chunkSize = Math.floor((availableSpaceForTable - 10) / rowHeight); // Adjust as needed
      pdf.addPage();
      drawHeaderAndBorder();
      startY = centerY + 10; // Adjust as needed
    }

    // Add total row on the last page
    if (endIdx >= products.length) {
      tableProducts.push(emptyRow, totalRow, total, totalWithDicount, totalRowtow, totalRowthreee);
    }

    pdf.autoTable({
      theme: 'plain',
      columns: tableColumns,
      body: [...tableProducts],
      startY: startY,
      didParseCell: (data) => {
        const lastRowIndex = tableProducts.length - 1;

        data.cell.styles.lineColor = [0, 0, 0];

        // Apply bold font style to the last 5 rows of the last page
        if (endIdx >= products.length && data.row.index >= lastRowIndex - 4) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.lineColor = [0, 0, 0];
        }
      },
      columnStyles: {
        productName: { cellWidth: 60, overflow: 'linebreak' },
        enteredQuantity: { cellWidth: 40, overflow: 'linebreak' },
        sellingPrice: { cellWidth: 50, overflow: 'linebreak' },
        totalPrice: { cellWidth: 50, overflow: 'linebreak' },
      },
    });

    // Update the startY for the next iteration
    startY = pdf.autoTable.startY + 10; // Adjust as needed
  }

  pdf.save(`Mr.${customerDetails.customerName}_invoice_${formattedDate}.pdf`);
};
