import React, { useState } from 'react';
import ResponseModal from '../Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { addQuntity, displayAlert, hideAlert } from '../../features/ColomboShowroom/ColomboShowroomStockSlice';

const QuantityManagement = ({ data }) => {
  const { id, bin, productName, category, supplier, companyCode, quantity } = data;

  const dispatch = useDispatch();

  const { alertType, alertText, isLoading } = useSelector((state) => state.ColomboShowroomStock);

  const [quantityValue, setQuantityValue] = useState(0);

  const quantityInputHandler = (e) => {
    setQuantityValue(Number(e.target.value));
  };

  const quantityInHandler = (e) => {
    e.preventDefault();
    if (!quantityValue) {
      return dispatch(displayAlert());
    }
    dispatch(addQuntity({ id, quantityValue }));
  };

  const ModalCloseHandler = () => {
    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />

      <div className="flex flex-row justify-between gap-5">
        <div className="w-2/5">
          <label className="block mb-2">Product ID</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={id} readOnly={true} />
        </div>

        <div className="w-4/5">
          <label className="block mb-2">Product Name</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={productName} readOnly={true} />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Company Code</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={companyCode} readOnly={true} />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Bin</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={bin} readOnly={true} />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Supplier</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={supplier} readOnly={true} />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Available Quantity</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={quantity} readOnly={true} />
        </div>
      </div>

      <div>
        <label className="block mb-2">Quantity</label>
        <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" onChange={quantityInputHandler} />
      </div>

      <br />

      <div className="flex flex-row gap-5">
        <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={quantityInHandler}>
          {isLoading ? (
            <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
          ) : (
            'Quantity IN'
          )}
        </button>
      </div>
    </>
  );
};

export default QuantityManagement;
