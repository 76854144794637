import React from 'react';
import Edit from './Edit';
import QuantityManagement from './QuantityManagement';
import Delete from './Delete';
import ServiceVehicle from './ServiceVehicle';
import AddProduct from './AddProduct';
import ThirdPartyStock from './ThirdPartyStock';

const ModalContent = ({ data, title, closeModalHandler }) => {
  let formTitle = '';

  switch (title) {
    case 'Update quntity':
      formTitle = 'Quantity Management';
      break;

    case 'Add to service vehicle':
      formTitle = 'Add To Service Vehicle';
      break;

    case 'Edit':
      formTitle = 'Update Product Details';
      break;

    case 'Delete':
      formTitle = 'Delete Product';
      break;

    case 'Add Product':
      formTitle = 'Add Product';
      break;

    case 'Customer':
      formTitle = 'Customer Invoice';
      break;

    case 'Third Party Stock':
      formTitle = 'Third Party Product Out';
      break;

    default:
      formTitle = 'Delete Product';
      break;
  }

  return (
    <>
      <h2 className="text-center font-bold text-2xl">{formTitle}</h2>
      <form className="mx-6 space-y-3 my-5">
        {title === 'Update quntity' && <QuantityManagement data={data} closeModalHandler={closeModalHandler} />}
        {title === 'Edit' && <Edit data={data} closeModalHandler={closeModalHandler} />}
        {title === 'Delete' && <Delete data={data} />}
        {title === 'Add to service vehicle' && <ServiceVehicle data={data} closeModalHandler={closeModalHandler} />}
        {title === 'Add Product' && <AddProduct closeModalHandler={closeModalHandler} />}
        {title === 'Third Party Stock' && <ThirdPartyStock data={data} closeModalHandler={closeModalHandler} />}
      </form>
    </>
  );
};

export default ModalContent;
