import React, { useState, useEffect } from 'react';
import ResponseModal from '../Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { outQuntity, displayAlert, hideAlert, getThirdPartyAssignee } from '../../features/ThirdPartyStock/ThirdPartyStockSlice';

const ThirdPartyStock = ({ data, closeModalHandler }) => {
  const { id, bin, productName, companyCode, quantity } = data;

  const dispatch = useDispatch();

  const { alertType, alertText, isLoading, ThirdPartyAssignee } = useSelector((state) => state.ThirdPartyStock);

  const [thirdPartyStockValues, setThirdPartyStockValues] = useState({ assignee: '', assigneeId: '', quantityValue: 0, description: '' });

  const [assigneeValueSearch, setAssigneeValueSearch] = useState(false);

  const { assignee, assigneeId, quantityValue, description } = thirdPartyStockValues;

  const InputHandler = (e) => {
    const { name, value } = e.target;
    setThirdPartyStockValues((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'assignee') {
      setAssigneeValueSearch(true);
    }
  };

  const assigneeSelectedSearchValue = (name, id) => {
    setThirdPartyStockValues((prevFormData) => ({
      ...prevFormData,
      assignee: name,
      assigneeId: id,
    }));
    setAssigneeValueSearch(false);
  };

  const quantityInHandler = (e) => {
    e.preventDefault();

    if (!quantityValue || !assignee || !description) {
      return dispatch(displayAlert());
    }

    console.log(typeof quantityValue);

    if (quantityValue <= 0 || isNaN(quantityValue)) {
      return dispatch(displayAlert({ alertText: 'Please enter a valid quantity.', alertType: 'error' }));
    }

    dispatch(outQuntity({ id, assigneeId, quantityValue: Number(quantityValue), description, assignee }));
  };

  const ModalCloseHandler = () => {
    if (alertType === 'success') {
      closeModalHandler();
    }
    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  useEffect(() => {
    dispatch(getThirdPartyAssignee(assignee));
  }, [assignee]);

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />

      <div className="flex flex-row justify-between gap-5">
        {/* <div className="w-2/5">
          <label className="block mb-2">Product ID</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={id} readOnly={true} />
        </div> */}

        <div className=" w-3/5">
          <label className="block mb-2">Company Code</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={companyCode} readOnly={true} />
        </div>

        <div className="w-4/5">
          <label className="block mb-2">Product Name</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={productName} readOnly={true} />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Bin</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={bin} readOnly={true} />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Available Quantity</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={quantity} readOnly={true} />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5"></div>
      <div className="flex flex-row justify-between gap-5">
        <div className="w-4/5">
          <label className="block mb-2">Assignee</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            name="assignee"
            type="text"
            value={assignee}
            onChange={InputHandler}
            autoComplete="off"
          />
          {ThirdPartyAssignee.length > 0 && assigneeValueSearch && (
            <div className='"w-4/5 max-h-16 bg-blue-700 rounded-lg mt-1 overflow-y-scroll py-1 '>
              {ThirdPartyAssignee.map((item) => {
                return (
                  <h4
                    className="text-white text-lg z-3 pl-2 auto cursor-pointer"
                    key={item.id}
                    onClick={() => assigneeSelectedSearchValue(item.name, item.id)}
                  >
                    {item.name}
                  </h4>
                );
              })}
            </div>
          )}
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Quantity</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            name="quantityValue"
            type="text"
            onChange={InputHandler}
          />
        </div>
      </div>

      <div>
        <label className="block mb-2">Description</label>
        <textarea className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" name="description" type="text" onChange={InputHandler} />
      </div>

      <br />

      <div className="flex flex-row gap-5">
        <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={quantityInHandler} disabled={isLoading}>
          {isLoading ? (
            <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
          ) : (
            'Quantity OUT'
          )}
        </button>
      </div>
    </>
  );
};

export default ThirdPartyStock;
