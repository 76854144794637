import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAServiceVehicle, displayAlert, hideAlert } from '../../features/ServiceVehicle/ServiceVehicleSlice';
import ResponseModal from '../Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';

const AddServiceVehicle = ({ data }) => {
  // const { product, remainingQuantity, quantity } = data;

  const { alertType, alertText, isLoading } = useSelector((state) => state.ServiceVehicle);

  const dispatch = useDispatch();

  const [vehicleDetails, setVehicleDetails] = useState({ vehicleNumber: '', vehicleType: '' });

  const vehicleNumberRegex = /^[A-Za-z]{2,3}-\d{4}$/;

  const { vehicleNumber, vehicleType } = vehicleDetails;

  const serviceVehiclesTypes = [
    { id: 2, type: 'Lorry' },
    { id: 1, type: 'Cab' },
  ];

  const ModalCloseHandler = () => {
    dispatch(hideAlert());
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;

    setVehicleDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ConfirmbuttonHandler = (e) => {
    e.preventDefault();
    if (!vehicleType || !vehicleNumber) {
      return dispatch(displayAlert());
    }

    if (!vehicleNumberRegex.test(vehicleNumber)) {
      return dispatch(displayAlert({ alertType: 'error', alertText: 'Please enter valid vehicle number' }));
    }
    dispatch(addAServiceVehicle({ type: vehicleType, vehicleNumber: vehicleNumber.toUpperCase() }));
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />
      <div>
        <label className="block mb-2">Select Service Vehicle</label>
        <select className="border border-gray-300 w-full p-2 rounded-lg" id="dropdown" name="vehicleType" value={vehicleType} onChange={inputHandler}>
          <option value="" className="text-center">
            Select a Service Vehicle
          </option>
          {serviceVehiclesTypes?.map((item) => {
            return (
              <option key={item.id} value={item.type} className="text-center">
                {item.type}
              </option>
            );
          })}
        </select>
      </div>

      <div className="w-full">
        <label className="block mb-2">Vehicle Number</label>
        <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" name="vehicleNumber" onChange={inputHandler} />
      </div>

      <br />

      <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={ConfirmbuttonHandler}>
        {isLoading ? (
          <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
        ) : (
          'Confirm'
        )}
      </button>
    </>
  );
};

export default AddServiceVehicle;
