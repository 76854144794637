import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceVehicles, hideAlert, displayAlert } from '../../features/ServiceVehicle/ServiceVehicleSlice';
import PropagateLoader from 'react-spinners/PulseLoader';

const ServiceVehicles = ({ jobNoteNumber }) => {
  const dispatch = useDispatch();
  const { serviceVehicles, isLoading } = useSelector((state) => state.ServiceVehicle);

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  useEffect(() => {
    dispatch(getServiceVehicles());
  }, []);

  return (
    <div className="relative overflow-auto max-h-96">
      {isLoading ? (
        <div className="flex justify-center">
          <PropagateLoader color={'red'} loading={isLoading} cssOverride={override} size={12} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <table className="table-auto w-full">
          <thead className="bg-black ">
            <tr>
              <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Vehicle ID</th>
              <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Type</th>
              <th className="sticky top-0 z-20 text-white px-4 py-3 text-left bg-black ">Vehicle Number</th>
            </tr>
          </thead>
          <tbody>
            {serviceVehicles.map((item) => (
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800" key={item.id}>
                <td className="text-gray-950 p-5 ">{item.id}</td>
                <td className="text-gray-950 p-5  ">{item.type}</td>
                <td className="text-gray-950 p-5 ">{item.vehicleNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ServiceVehicles;
