import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsPrinterFill } from 'react-icons/bs';
import PropagateLoader from 'react-spinners/PulseLoader';
import BtnContainer from '../../components/BtnContainer';
import Modal from '../../components/Modals/JobNotesModal';
import ModalContentJobNotes from '../../components/JobNoteModals';
import axios from 'axios';
import { BASE_URL } from '../../constants';
import { getCustomerDirectJobNotes, getCustomerDirectJobNotesProducts, changePageNumber } from '../../features/ColomboShowroom/DirectJobNoteSlice';

const DirectJobNotesColombo = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [searchText, setSearchText] = useState('');

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  const dispatch = useDispatch();
  const { directJobNoteCustomerDetailsColombo, isLoading, page, numOfPages } = useSelector((state) => state.DirectJobNoteColombo);

  const modalhandler = (title, item) => {
    setEditModalOpen(true);
    setModalTitle(title);
    setSelectedRowData(item);
    dispatch(getCustomerDirectJobNotesProducts(item.id));
  };

  const closeModalHandler = () => {
    setEditModalOpen(false);
  };

  const searchHandler = () => {
    setSearchText('');
  };

  const exportExcel = async () => {
    try {
      const response = await axios.get(`${BASE_URL}servicevehicle/export`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a Blob URL and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'jobNotes.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting Excel:', error);
    }
  };

  useEffect(() => {
    dispatch(getCustomerDirectJobNotes(searchText));
  }, [searchText, page]);

  return (
    <div className="flex flex-col max-w-7xl mx-auto">
      <h2 className="text-xs sm:text-lg md:text-xl lg:text-xl font-semibold">Direct Job Notes</h2>

      <div className="flex flex-row items-center mb-5 shadow-sm ">
        <input
          className="w-full py-2 px-2 border border-r-slate-950 rounded-l-md text-black"
          type="text"
          placeholder="search here"
          value={searchText}
          onChange={(text) => setSearchText(text.target.value)}
        />

        <button className="bg-blue-500 py-2 px-5 rounded-r-md" onClick={() => searchHandler()} disabled={searchText === ''}>
          {searchText === '' ? 'Search' : 'Clear'}
        </button>
      </div>

      <div className="flex flex-row gap-5 mb-2 items-center justify-end">
        <PropagateLoader color={'red'} loading={isLoading} cssOverride={override} size={12} aria-label="Loading Spinner" data-testid="loader" />

        <>
          <BsPrinterFill className="caret-violet-600" style={{ color: 'black' }} size={28} title="Export PDF" onClick={exportExcel} />
        </>
      </div>

      <div className="max-h-screen overflow-auto">
        {directJobNoteCustomerDetailsColombo?.length === 0 ? (
          <h1 className="text-slate-950 text-center text-xl">Sorry No Data founded</h1>
        ) : (
          <>
            <table className="table-auto w-full ">
              <thead className="bg-black  sticky top-0">
                <tr>
                  <th className="text-white px-4 py-3 text-left hidden sm:table-cell">Job ID</th>
                  <th className="text-white  px-4 py-3 text-left">Job Note Number</th>
                  <th className="text-white px-4 py-3 text-left hidden sm:table-cell">Customer Name</th>
                  <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Customer Address</th>
                  <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Customer Phone</th>
                  {/* <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Total</th> */}
                  <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Date</th>
                </tr>
              </thead>
              <tbody>
                {directJobNoteCustomerDetailsColombo?.map((item) => {
                  return (
                    <tr
                      className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800 cursor-pointer"
                      key={item.id}
                      onClick={() =>
                        modalhandler('direct JobNote Customer Colombo', {
                          id: item.id,
                          jobNoteNumber: item.jobNoteNumber,
                          customerNam: item.customerNam,
                        })
                      }
                    >
                      <td className="text-gray-950 p-5   hidden sm:table-cell">{item.id}</td>
                      <td className="text-gray-950 p-5">{item.jobNoteNumber}</td>
                      <td className="text-gray-950 p-5  hidden sm:table-cell">{item.customerName}</td>
                      <td className="text-gray-950 p-5 hidden sm:table-cell">{item.customerAddress}</td>
                      <td className="text-gray-950 p-5 hidden sm:table-cell">{item.customerPhone}</td>
                      {/* <td className="text-gray-950 p-5 hidden sm:table-cell">Rs.{item.total}</td> */}
                      <td className="text-gray-950 p-5 hidden sm:table-cell">{item.createdAt.slice(0, 10)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
      {numOfPages > 1 && <BtnContainer numOfPages={numOfPages} page={page} changePageNumber={changePageNumber} />}

      {/* Modal */}
      <Modal open={editModalOpen} closeHandler={closeModalHandler}>
        <ModalContentJobNotes data={selectedRowData} title={modalTitle} closeModalHandler={closeModalHandler} />
      </Modal>
    </div>
  );
};

export default DirectJobNotesColombo;
