import React from 'react';
import { deleteProduct, displayAlert, hideAlert } from '../../features/Product/MainStockSlice';
import { useSelector, useDispatch } from 'react-redux';

const Delete = ({ data }) => {
  const { id, bin, productName, category, supplier, companyCode, sellingPrice, unitCost, quantity } = data;

  const dispatch = useDispatch();
  const { alertType, alertText, isLoading } = useSelector((state) => state.MainStock);

  return (
    <>
      <div className="flex flex-row justify-between gap-5">
        <div className="w-2/5">
          <label className="block mb-2">Product ID</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={id} readOnly={true} />
        </div>

        <div className="w-4/5">
          <label className="block mb-2">Product Name</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="productName"
            value={productName}
            readOnly={true}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Company Code</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="companyCode"
            value={companyCode}
            readOnly={true}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Bin</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" name="bin" value={bin} readOnly={true} />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Supplier</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="supplier"
            value={supplier}
            readOnly={true}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Category</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="category"
            value={category}
            readOnly={true}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Unit Cost (Rs)</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="unitCost"
            value={unitCost}
            readOnly={true}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Selling Price (Rs)</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="sellingPrice"
            value={sellingPrice}
            readOnly={true}
          />
        </div>
      </div>

      <div>
        <label className="block mb-2">Quantity</label>
        <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" readOnly={true} value={quantity} />
      </div>

      <br />

      <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold">Delete Product</button>
    </>
  );
};

export default Delete;
