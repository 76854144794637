import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsClipboard2Fill, BsRepeat, BsPlusSquareFill, BsPersonFillGear, BsPrinterFill } from 'react-icons/bs';
import { getThirdPartyStock } from '../../features/ThirdPartyStock/ThirdPartyStockSlice';
import PropagateLoader from 'react-spinners/PulseLoader';
import BtnContainer from '../../components/BtnContainer';
import Modal from '../../components/Modals/Modal';
import ModalAssignees from '../../components/Modals/JobNotesModal';
import ModalContentThirdPartyStock from '../../components/ThirdPartyStockModals';
import axiosInstance from '../../utils/AuthFetch';

const ThirdPartyStock = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalOpenAssignees, setEditModalOpenAssignees] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [searchText, setSearchText] = useState('');

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  const dispatch = useDispatch();
  const { ThirdPartyStock, isLoading, page, numOfPages } = useSelector((state) => state.ThirdPartyStock);
  const { user } = useSelector((state) => state.Auth);

  const modalhandler = (title, item) => {
    setEditModalOpen(true);
    setModalTitle(title);
    setSelectedRowData(item);
  };

  const closeModalHandler = () => {
    setEditModalOpen(false);
  };

  //get all Assignees modal
  const modalhandlerAssignees = (title, item) => {
    setEditModalOpenAssignees(true);
    setModalTitle(title);
    setSelectedRowData(item);
  };

  const closeModalHandlerAssignees = () => {
    setEditModalOpenAssignees(false);
  };

  const searchHandler = () => {
    setSearchText('');
  };

  const exportExcel = async () => {
    try {
      const response = await axiosInstance.get(`thirdpartystock/exportExcel/stock`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a Blob URL and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Third_party_stock.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting Excel:', error);
    }
  };

  useEffect(() => {
    dispatch(getThirdPartyStock(searchText));
  }, [searchText, page]);

  return (
    <div className="flex flex-col max-w-7xl mx-auto">
      <h2 className="text-xs sm:text-lg md:text-xl lg:text-xl font-semibold">Third Party Stock</h2>
      <div className="flex flex-row items-center mb-5 shadow-sm ">
        <input
          className="w-full py-2 px-2 border border-r-slate-950 rounded-l-md text-black"
          type="text"
          placeholder="search here"
          value={searchText}
          onChange={(text) => setSearchText(text.target.value)}
        />

        <button className="bg-blue-500 py-2 px-5 rounded-r-md" onClick={() => searchHandler()} disabled={searchText === ''}>
          {searchText === '' ? 'Search' : 'Clear'}
        </button>
      </div>

      <div className="flex flex-row gap-5 mb-2 items-center justify-end">
        <PropagateLoader color={'red'} loading={isLoading} cssOverride={override} size={12} aria-label="Loading Spinner" data-testid="loader" />

        <>
          {user.role !== 'colombo showroom' && (
            <>
              {' '}
              <BsPersonFillGear
                className="caret-violet-600 cursor-pointer"
                style={{ color: 'black' }}
                size={28}
                title="Assignees"
                onClick={() => modalhandlerAssignees('Assignees')}
              />
              <BsPlusSquareFill
                className="caret-violet-600 cursor-pointer"
                style={{ color: 'black' }}
                size={28}
                title="Add a Assigneet"
                onClick={() => modalhandler('Add a Assignee')}
              />
            </>
          )}
          <BsPrinterFill className="caret-violet-600 cursor-pointer" style={{ color: 'black' }} size={28} title="Export PDF" onClick={exportExcel} />
        </>
      </div>

      <div className="max-h-screen overflow-auto">
        {ThirdPartyStock.length === 0 ? (
          <h1 className="text-slate-950 text-center text-xl">Sorry No Data founded</h1>
        ) : (
          <>
            <table className="table-auto w-full ">
              <thead className="bg-black  sticky top-0">
                <tr>
                  <th className="text-white px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">ID</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left">Product Name</th>
                  <th className="text-white px-4 py-3 text-sm sm:text-md md:text-base text-left  sm:table-cell">Quantity</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">description</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left  sm:table-cell">Assignee</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">Date</th>
                  {user.role !== 'colombo showroom' && (
                    <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {ThirdPartyStock.map((item) => {
                  return (
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800" key={item.id}>
                      <td className="text-gray-950 p-5  hidden text-sm sm:text-md md:text-base sm:table-cell">{item.id}</td>
                      <td className="text-gray-950 p-5">{item.product.productName}</td>
                      <td className="text-gray-950 p-5   text-sm sm:text-md md:text-base sm:table-cell">{item.quantity}</td>
                      <td className="text-gray-950 p-5 hidden text-sm sm:text-md md:text-base sm:table-cell">{item.description}</td>
                      <td className="text-gray-950 p-5  text-sm sm:text-md md:text-base sm:table-cell">{item.third_party_assignee.name}</td>
                      <td className="text-gray-950 p-5 hidden text-sm sm:text-md md:text-base sm:table-cell">{item.createdAt.slice(0, 10)}</td>
                      {user.role !== 'colombo showroom' && (
                        <td className="text-blue-700 p-5 hidden text-sm sm:text-md md:text-base sm:flex sm:flex-row sm:items-center cursor-pointer">
                          <BsRepeat
                            className="mr-3"
                            size={20}
                            name="Return Product to main stock"
                            title="Return Product to main stock"
                            onClick={() => modalhandler('Return Product to main stock', item)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
      {numOfPages > 1 && <BtnContainer />}

      {/* Modal */}
      <Modal open={editModalOpen} closeHandler={closeModalHandler}>
        <ModalContentThirdPartyStock data={selectedRowData} title={modalTitle} closeModalHandler={closeModalHandler} />
      </Modal>

      {/* Modal */}
      <ModalAssignees open={editModalOpenAssignees} closeHandler={closeModalHandlerAssignees}>
        <ModalContentThirdPartyStock data={selectedRowData} title={modalTitle} closeModalHandler={closeModalHandlerAssignees} />
      </ModalAssignees>
    </div>
  );
};

export default ThirdPartyStock;
