import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../../constants';
import axiosInstance from '../../utils/AuthFetch';
import { getMainStockItems } from '../Product/MainStockSlice';

const initialState = {
  ThirdPartyStock: [],
  totalProducts: 1,
  numOfPages: 1,
  page: 1,
  isLoading: false,
  errorMsg: 'is currently empty',
  alertType: '',
  alertText: '',
  ThirdPartyAssignee: [],
  ThirdPartyAssignees: [],
  jobNotes: [],
  jobNotesProducts: [],
};

//registerThirdParty
export const registerThirdParty = createAsyncThunk('ThirdPartyStock/registerThirdParty', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`thirdpartystock/assignee/register`, {
      name: data.assignee,
    });
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const getThirdPartyStock = createAsyncThunk('ThirdPartyStock/getThirdPartyStock', async (searchText, thunkAPI) => {
  try {
    const { page } = thunkAPI.getState().MainStock;
    const { token } = thunkAPI.getState().Auth;

    let pagePram = page;

    if (searchText) {
      pagePram = 1;
    }
    const resp = await axiosInstance.get(`thirdpartystock?searchText=${searchText}&page=${pagePram}`);

    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

//thirdPartyStockOut
export const outQuntity = createAsyncThunk('ThirdPartyStock/outQuntity', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}thirdpartystock/${data.id}`, {
      assigneeId: data.assigneeId,
      quantity: data.quantityValue,
      description: data.description,
      assignee: data.assignee,
    });
    thunkAPI.dispatch(getMainStockItems(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const getThirdPartyAssignee = createAsyncThunk('ThirdPartyStock/getThirdPartyAssignee', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`thirdpartystock/assignee/name?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

export const addJob = createAsyncThunk('ThirdPartyStock/addJob', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}thirdpartystock/jobNotes/jobNote`, data);
    thunkAPI.dispatch(getThirdPartyStock(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const getAllJobNotes = createAsyncThunk('ThirdPartyStock/getAllJobNotes', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`${BASE_URL}thirdpartystock/jobNotes/jobNote?searchText=${searchText}`);
    thunkAPI.dispatch(getThirdPartyStock(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get selected jobnote products
export const getJobNoteProducts = createAsyncThunk('ThirdPartyStock/getJobNoteProducts', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`${BASE_URL}thirdpartystock/jobNotes/jobProducts?jobNumber=${data}`);
    thunkAPI.dispatch(getThirdPartyStock(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

export const retrunQuantity = createAsyncThunk('ThirdPartyStock/retrunQuantity', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`${BASE_URL}thirdpartystock/product/return`, data);
    thunkAPI.dispatch(getThirdPartyStock(''));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//getAllThirdPartyAssignee
export const getAllThirdPartyAssignee = createAsyncThunk('ThirdPartyStock/getAllThirdPartyAssignee', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`thirdpartystock/assignee`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

// for add jobNotes
export const getThirdPartyStockByAssigneeId = createAsyncThunk('ThirdPartyStock/getThirdPartyStockByAssigneeId', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`${BASE_URL}thirdpartystock/stock?thirdPartyID=${data.thirdPartyID}&searchText=${data.searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('something went wrong');
  }
});

const ThirdPartyStockSlice = createSlice({
  name: 'ThirdPartyStock',
  initialState,
  reducers: {
    changePageNumber: (state, { payload }) => {
      state.page = payload;
    },
    displayAlert: (state, { payload }) => {
      state.showAlert = true;
      state.alertType = payload ? payload.alertType : 'error';
      state.alertText = payload ? payload.alertText : 'Please provide all values';
    },
    hideAlert: (state) => {
      state.showAlert = false;
      state.alertType = '';
      state.alertText = '';
    },
  },

  extraReducers: (builder) => {
    builder
      //get Products
      .addCase(getThirdPartyStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getThirdPartyStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ThirdPartyStock = action.payload;
        state.totalProducts = action.payload.totalProducts;
        state.numOfPages = action.payload.numOfPages;
      })
      .addCase(getThirdPartyStock.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      //outQuntity
      .addCase(outQuntity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(outQuntity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully product is released';
      })
      .addCase(outQuntity.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        console.log('action.payload', action.payload);
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //registerThirdParty
      .addCase(registerThirdParty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerThirdParty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'A third-party assignee has been registered successfully';
      })
      .addCase(registerThirdParty.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getThirdPartyAssignee
      .addCase(getThirdPartyAssignee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getThirdPartyAssignee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ThirdPartyAssignee = action.payload;
        state.alertType = 'success';
        state.alertText = '';
      })
      .addCase(getThirdPartyAssignee.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //addJob
      .addCase(addJob.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'Successfully Job Note Added';
      })
      .addCase(addJob.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //returnProduct
      .addCase(retrunQuantity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(retrunQuantity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'Successfully Product is Returned to Main Stock';
      })
      .addCase(retrunQuantity.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getAllThirdPartyAssignee
      .addCase(getAllThirdPartyAssignee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllThirdPartyAssignee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ThirdPartyAssignees = action.payload;
      })
      .addCase(getAllThirdPartyAssignee.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getThirdPartyStockByAssigneeId
      .addCase(getThirdPartyStockByAssigneeId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getThirdPartyStockByAssigneeId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ThirdPartyStock = action.payload;
      })
      .addCase(getThirdPartyStockByAssigneeId.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getAllJobNotes
      .addCase(getAllJobNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllJobNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobNotes = action.payload;
      })
      .addCase(getAllJobNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getJobNoteProduct
      .addCase(getJobNoteProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getJobNoteProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobNotesProducts = action.payload;
      })
      .addCase(getJobNoteProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.alertType = 'error';
        state.alertText = action.payload;
      });
  },
});

export const { changePageNumber, displayAlert, hideAlert } = ThirdPartyStockSlice.actions;

export default ThirdPartyStockSlice.reducer;
