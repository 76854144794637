import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrunQuantity, displayAlert, hideAlert } from '../../features/ThirdPartyStock/ThirdPartyStockSlice';
import ResponseModal from '../Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';

const ReturnProduct = ({ data }) => {
  const { id, assigneeId, third_party_assignee, product, quantity } = data;
  const { alertType, alertText, isLoading } = useSelector((state) => state.ThirdPartyStock);
  const dispatch = useDispatch();

  const [returnProductDetails, setReturnProductDetails] = useState({ retrunQuantityValue: 0, description: '' });

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setReturnProductDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const confirmHandler = (e) => {
    e.preventDefault();

    const { retrunQuantityValue, description } = returnProductDetails;
    if (!retrunQuantityValue || !description) {
      return dispatch(displayAlert());
    }

    dispatch(
      retrunQuantity({
        assigneeId,
        productId: product.id,
        third_party_stock_Id: id,
        retrunQuantity: Number(retrunQuantityValue),
        description,
      })
    );
  };

  const ModalCloseHandler = () => {
    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />

      <div>
        <label className="block mb-2">Product Name</label>
        <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={product.productName} readOnly={true} />
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className=" w-3/5">
          <label className="block mb-2">Assignee</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            value={third_party_assignee.name}
            readOnly={true}
          />
        </div>
        <div className=" w-2/5">
          <label className="block mb-2">Company Code</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={product.companyCode} readOnly={true} />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5">
        <div className="w-3/5">
          <label className="block mb-2">Remaining Quantity</label>
          <input className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" type="text" value={quantity} readOnly={true} />
        </div>
        <div className="w-3/5">
          <label className="block mb-2">Retrun Quantity</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            name="retrunQuantityValue"
            type="text"
            onChange={inputHandler}
          />
        </div>
      </div>

      <div>
        <label className="block mb-2">description</label>
        <textarea className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300" name="description" type="text" onChange={inputHandler} />
      </div>

      <br />

      <button className="w-full bg-blue-700 rounded-lg p-2 text-white font-semibold" onClick={confirmHandler}>
        {isLoading ? (
          <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={6} aria-label="Loading Spinner" data-testid="loader" />
        ) : (
          'Confirm'
        )}
      </button>
    </>
  );
};

export default ReturnProduct;
