import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllThirdPartyAssignee } from '../../features/ThirdPartyStock/ThirdPartyStockSlice';
import PropagateLoader from 'react-spinners/PulseLoader';

const Assignees = () => {
  console.log('getThirdPartyAssignee');

  const dispatch = useDispatch();
  const { ThirdPartyAssignees, isLoading } = useSelector((state) => state.ThirdPartyStock);

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  useEffect(() => {
    dispatch(getAllThirdPartyAssignee());
  }, []);

  console.log('====================================');
  console.log(ThirdPartyAssignees);
  console.log('====================================');

  return (
    <div className="relative overflow-auto max-h-96">
      {isLoading ? (
        <div className="flex justify-center">
          <PropagateLoader color={'red'} loading={isLoading} cssOverride={override} size={12} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <table className="table-auto w-full">
          <thead className="bg-black ">
            <tr>
              <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Assignee ID</th>
              <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Assignee Name</th>
            </tr>
          </thead>
          <tbody>
            {ThirdPartyAssignees.map((item) => (
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800" key={item.id}>
                <td className="text-gray-950 p-5 ">{item.id}</td>
                <td className="text-gray-950 p-5  ">{item.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Assignees;
