import React from 'react';
import close from '../asserts/icons/close.png';
import checkmark from '../asserts/icons/checkmark.png';

const ResponseModal = ({ open, alertText, alertType, closeHandler }) => {
  if (!open) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center text-slate-950 z-10">
      <div className="w-[300px] flex flex-col">
        <div className="bg-white p-2 rounded-lg flex flex-col justify-center items-center">
          <img src={alertType === 'error' ? close : checkmark} className="w-20 h-20 mb-2" />
          <h2 className="text-base font-semibold capitalize mb-1">{alertType === 'error' ? 'Something went wrong!' : 'Success'}</h2>
          <h2 className="text-xl text-center font-semibold capitalize">{alertText}</h2>
          <button className="bg-blue-700 rounded-lg  text-white font-semibold mt-2 px-5 py-2" onClick={closeHandler}>
            {alertType === 'error' ? 'Close' : 'Ok'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponseModal;
