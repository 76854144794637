import { configureStore } from '@reduxjs/toolkit';
import MainStockReducer from './features/Product/MainStockSlice';
import AuthSliceReducer from './features/user/AuthSlice';
import CustomerInvoiceReducer from './features/Product/CustomerInvoice';
import ServiceVehicleReducer from './features/ServiceVehicle/ServiceVehicleSlice';
import ThirdPartyStockReducer from './features/ThirdPartyStock/ThirdPartyStockSlice';
import ColomboShowroomStockReducer from './features/ColomboShowroom/ColomboShowroomStockSlice';
import DirectJobNoteReducer from './features/DirectJobNote/DirectJobNoteSlice';
import DirectJobNoteColomboReducer from './features/ColomboShowroom/DirectJobNoteSlice';

export const store = configureStore({
  reducer: {
    MainStock: MainStockReducer,
    Auth: AuthSliceReducer,
    CustomerInvoice: CustomerInvoiceReducer,
    ServiceVehicle: ServiceVehicleReducer,
    ThirdPartyStock: ThirdPartyStockReducer,
    ColomboShowroomStock: ColomboShowroomStockReducer,
    DirectJobNote: DirectJobNoteReducer,
    DirectJobNoteColombo: DirectJobNoteColomboReducer,
  },
});
