import React, { useEffect, useState } from 'react';
import {
  BsTruck,
  BsFillPencilFill,
  BsFillTrashFill,
  BsFillHouseXFill,
  BsPrinterFill,
  BsPlusSquareFill,
  BsCartPlusFill,
  BsPersonFillGear,
} from 'react-icons/bs';
import Modal from '../../components/Modals/Modal';
import ModalContent from '../../components/ColomboShowroomModals';
import { useDispatch, useSelector } from 'react-redux';
import { getColomboShowroomStockItems, changePageNumber } from '../../features/ColomboShowroom/ColomboShowroomStockSlice';
import PropagateLoader from 'react-spinners/PulseLoader';
import BtnContainer from '../../components/BtnContainer';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/AuthFetch';
import { commaSeperator } from '../../utils/numberFormater';

const ColomboShowRoomStock = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  const dispatch = useDispatch();
  const { ColomboShowroomStockItems, isLoading, page, numOfPages } = useSelector((state) => state.ColomboShowroomStock);

  const modalhandler = (title, item) => {
    setEditModalOpen(true);
    setModalTitle(title);
    setSelectedRowData(item);
  };

  const closeModalHandler = () => {
    setEditModalOpen(false);
  };

  const searchHandler = () => {
    setSearchText('');
  };

  const exportExcel = async () => {
    try {
      const response = await axiosInstance.get(`colomboshowroom/exportExcel/stock`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a Blob URL and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'colombo_showroom_stock.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting Excel:', error);
    }
  };

  useEffect(() => {
    dispatch(getColomboShowroomStockItems(searchText));
  }, [searchText, page]);

  return (
    <div className="flex flex-col max-w-7xl  mx-auto">
      <h2 className="text-xs sm:text-lg md:text-xl lg:text-xl font-semibold">Colombo Showroom Stock</h2>
      <div className="flex flex-row items-center mb-5 shadow-sm ">
        <input
          className="w-full py-2 px-2 border border-r-slate-950 rounded-l-md text-black"
          type="text"
          placeholder="search here"
          value={searchText}
          onChange={(text) => setSearchText(text.target.value)}
        />

        <button className="bg-blue-500 py-2 px-5 rounded-r-md" onClick={() => searchHandler()} disabled={searchText === ''}>
          {searchText === '' ? 'Search' : 'Clear'}
        </button>
      </div>

      <div className="flex flex-row gap-5 mb-2 items-center justify-end">
        <PropagateLoader color={'red'} loading={isLoading} cssOverride={override} size={12} aria-label="Loading Spinner" data-testid="loader" />

        <>
          {/* <BsCartPlusFill
            className="caret-violet-600"
            style={{ color: 'black' }}
            size={28}
            title="Add Product for Customer"
            onClick={() => navigate('customer-invoice')}
          /> */}
          <BsPlusSquareFill
            className="caret-violet-600"
            style={{ color: 'black' }}
            size={28}
            title="Add Product"
            onClick={() => modalhandler('Add Product')}
          />
          <BsPrinterFill className="caret-violet-600" style={{ color: 'black' }} size={28} title="Export PDF" onClick={exportExcel} />
        </>
      </div>

      <div className="max-h-screen overflow-auto">
        {ColomboShowroomStockItems.length === 0 ? (
          <h1 className="text-slate-950 text-center text-xl">Sorry No Data founded</h1>
        ) : (
          <>
            <table className="table-auto w-full ">
              <thead className="bg-black  sticky top-0">
                <tr>
                  <th className="text-white px-4 py-3 text-left hidden sm:table-cell">Product ID</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left">Product Name</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">Supplier</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left  hidden sm:table-cell">Company Code</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">Bin</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">Category</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">Unit Cost</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">Selling Price</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left">Quantity</th>
                  <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden sm:table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {ColomboShowroomStockItems.map((item) => {
                  return (
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800" key={item.id}>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base   hidden sm:table-cell">{item.id}</td>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base">{item.productName}</td>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base hidden sm:table-cell">{item.supplier}</td>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base  hidden sm:table-cell">{item.companyCode}</td>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base hidden sm:table-cell">{item.bin}</td>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base hidden sm:table-cell">{item.category}</td>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base hidden sm:table-cell">Rs{commaSeperator(item.unitCost)}</td>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base hidden sm:table-cell">
                        Rs{commaSeperator(item.sellingPrice)}
                      </td>
                      <td className="text-gray-950 p-5 text-sm sm:text-md md:text-base">{item.quantity}</td>
                      <td className="text-blue-700 p-5 flex flex-row items-center cursor-pointer">
                        <BsFillHouseXFill
                          className="mr-3"
                          size={20}
                          title="Update quntity"
                          name="Update quntity"
                          onClick={() => modalhandler('Update quntity', item)}
                        />
                        <BsFillPencilFill className="mr-3" size={20} title="Edit" onClick={() => modalhandler('Edit', item)} />
                        <BsFillTrashFill size={20} title="Delete" onClick={() => modalhandler('Delete', item)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
      {numOfPages > 1 && <BtnContainer numOfPages={numOfPages} page={page} changePageNumber={changePageNumber} />}

      {/* Modal */}
      <Modal open={editModalOpen} closeHandler={closeModalHandler}>
        <ModalContent data={selectedRowData} title={modalTitle} closeModalHandler={closeModalHandler} />
      </Modal>
    </div>
  );
};

export default ColomboShowRoomStock;
