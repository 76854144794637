import React from 'react';
import AddJobNote from './AddJobNote';
import ReturnProduct from './ReturnProduct';
import AddAssignee from './AddAssignee';
import Assignees from './Assignees';

const ModalContentVehicleStock = ({ data, title, closeModalHandler }) => {
  let formTitle = '';

  switch (title) {
    case 'Return Product to main stock':
      formTitle = 'Return Product to main stock';
      break;

    case 'Add a Assignee':
      formTitle = 'Add a Assignee';
      break;

    case 'Assignees':
      formTitle = 'Assignees';
      break;

    default:
      formTitle = 'Add a Job Note For Third Party Assignee';
      break;
  }

  return (
    <>
      <h2 className="text-center font-bold text-2xl">{formTitle}</h2>
      <form className="mx-6 space-y-3 my-5">
        {title === 'Add a Job Note' && <AddJobNote data={data} />}
        {title === 'Return Product to Main Stock' && <ReturnProduct data={data} />}
        {title === 'Add a Assignee' && <AddAssignee />}
        {title === 'Assignees' && <Assignees />}
      </form>
    </>
  );
};

export default ModalContentVehicleStock;
