import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../constants';

const token = localStorage.getItem('token');
const user = localStorage.getItem('user');

const initialState = {
  token: token,
  user: user ? JSON.parse(user) : null,
  isLoading: false,
  alertText: '',
  alertType: '',
  showAlert: false,
  errorMsg: 'is currently empty',
};

export const login = createAsyncThunk('Auth/login', async (credentials, thunkAPI) => {
  try {
    const resp = await axios.post(`${BASE_URL}auth/login`, credentials);
    return resp.data;
  } catch (error) {
    console.log(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

const addUserToLocalStorage = (user, token) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', token);
};

const removeFromTheLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    displayAlet: (state) => {
      state.showAlert = true;
      state.alertType = 'error';
      state.alertText = 'Please provide all values';
    },
    hideAlert: (state) => {
      state.showAlert = false;
      state.alertType = '';
      state.alertText = '';
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.alertType = '';
      state.alertText = '';
      removeFromTheLocalStorage();
    },
  },

  extraReducers: (builder) => {
    builder
      //login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.showAlert = true;
        state.alertText = 'Login Successful! Redirecting';
        state.alertType = 'success';
        addUserToLocalStorage(action.payload.user, action.payload.token);
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
        state.showAlert = true;
        state.alertText = action.payload;
        state.alertType = 'error';
      });
  },
});

export const { hideAlert, displayAlet, logOut } = AuthSlice.actions;
export default AuthSlice.reducer;
