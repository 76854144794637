import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropagateLoader from 'react-spinners/PulseLoader';
import { BsPrinterFill } from 'react-icons/bs';
import axiosInstance from '../../utils/AuthFetch';

const ThirdPartyJobNotesProductDetails = ({ jobDetails }) => {
  const { jobNotesProducts, jobNotesExcelSheet, isLoading } = useSelector((state) => state.ThirdPartyStock);

  const [loading, setLoading] = useState(false);

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  const exportExcel = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`thirdpartystock/exportExcel/jobNote?thirdPartyJobnoteId=${jobDetails.id}`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a Blob URL and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'vehicle_stock.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error exporting Excel:', error);
    }
  };

  return (
    <div className="relative overflow-auto max-h-96">
      {isLoading ? (
        <div className="flex justify-center">
          <PropagateLoader color={'red'} loading={isLoading} cssOverride={override} size={12} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <>
          <div className="flex justify-end mb-2">
            {loading ? (
              <div className="flex justify-end">
                <PropagateLoader color={'red'} loading={loading} cssOverride={override} size={10} aria-label="Loading Spinner" data-testid="loader" />
              </div>
            ) : (
              <BsPrinterFill
                className="caret-violet-600 cursor-pointer"
                style={{ color: 'black' }}
                size={28}
                title="Export PDF"
                onClick={exportExcel}
              />
            )}
          </div>
          <table className="table-auto w-full">
            <thead className="bg-black ">
              <tr>
                <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Job ID</th>
                <th className="sticky top-0 z-20 text-white px-4 py-3 text-left bg-black ">Product Name</th>
                <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Job Quantity</th>
              </tr>
            </thead>
            <tbody>
              {jobNotesProducts.map((item) => (
                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800" key={item.id}>
                  <td className="text-gray-950 p-5 ">{item.id}</td>
                  <td className="text-gray-950 p-5  ">{item.product.productName}</td>
                  <td className="text-gray-950 p-5 ">{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ThirdPartyJobNotesProductDetails;
