import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomerDirectJobNote, displayAlert, getProductsForSearch, hideAlert } from '../../features/DirectJobNote/DirectJobNoteSlice';
import { BsFillTrashFill, BsPrinterFill } from 'react-icons/bs';
import ResponseModal from '../../components/Modals/ResponseModal';
import PropagateLoader from 'react-spinners/PulseLoader';
import { generatallPDF } from '../../utils/InvoicePDF';

const AddDirectJobNote = () => {
  const [searchText, setSearchText] = useState('');
  const [products, setproducts] = useState([]);

  const [customerDetails, setCustomerDetails] = useState({ customerName: '', customerAddress: '', customerPhone: '', jobNoteNumber: '' });
  const isAllItemsFilled = Object.values(customerDetails).every((value) => !!value);

  const dispatch = useDispatch();
  const { searchProducts, isLoading, alertType, alertText } = useSelector((state) => state.DirectJobNote);

  const buttonHandler = (product) => {
    if (!products.filter((item) => item.id === product.id).length > 0) {
      setproducts((prevProducts) => [...prevProducts, { ...product, enteredQuantity: 0 }]);
      setSearchText('');
    }
  };

  const removeProductHandler = (productId) => {
    setproducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));
  };

  const calculateTotalPrice = () => {
    return Object.values(products).reduce((sum, product) => {
      if (product.enteredQuantity <= product.quantity) {
        return sum + product.enteredQuantity * product.sellingPrice;
      }
      return sum;
    }, 0);
  };

  const inputHandler = (productId, quantity) => {
    setproducts((prevProducts) => prevProducts.map((product) => (product.id === productId ? { ...product, enteredQuantity: quantity } : product)));
  };

  //confirm button visibility
  const areAllItemsConfirmed = (products) => {
    return products?.length > 0 && products.every((product) => product.enteredQuantity > 0 && product.quantity >= product.enteredQuantity);
  };

  const confirmButtonHandler = () => {
    const productQuntity = areAllItemsConfirmed(products);
    if (!productQuntity) {
      return dispatch(
        displayAlert({ alertText: 'Entered quantity is greater than available quantity or not enterd the quantity.', alertType: 'error' })
      );
    }
    dispatch(
      createCustomerDirectJobNote({
        ...customerDetails,
        total: totalPrice,
        selectedProducts: products.map((product) => ({ productId: product.id, quantity: product.enteredQuantity })),
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(getProductsForSearch(searchText));
  }, [searchText]);

  // useEffect(() => {
  //   if (products.length === 0) {
  //     setCustomerDetails((prevState) => ({
  //       ...prevState,
  //       discount: 0,
  //     }));
  //   }
  // }, [products]);

  const totalPrice = calculateTotalPrice();

  const ModalCloseHandler = () => {
    dispatch(hideAlert());
  };

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  const buttonVisibility = products?.length > 0 && isAllItemsFilled;

  return (
    <div className=" max-w-7xl flex justify-center flex-col mx-auto relative">
      <h2 className="text-xs sm:text-lg md:text-xl lg:text-xl font-semibold text-center mb-3">Add Direct Job Note</h2>
      <ResponseModal open={alertText} alertText={alertText} alertType={alertType} closeHandler={ModalCloseHandler} />
      <h2>Product Search</h2>
      <div className="w-full relative">
        <input
          className="w-full p-2 rounded-md border border-gray-300 mb-5"
          type="text"
          value={searchText}
          onChange={(text) => setSearchText(text.target.value)}
          placeholder="Search for products..."
        />

        {searchText && searchProducts?.length > 0 && (
          <ul className="bg-zinc-400 p-2 w-full overflow-y-scroll absolute text-center rounded-md z-10 max-h-40">
            {searchProducts?.map((item) => (
              <li className="" key={item.id}>
                <button onClick={() => buttonHandler(item)} className="text-black font-semibold">
                  {item.productName}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex flex-row justify-between gap-5 mb-5">
        <div className=" w-3/5">
          <label className="block mb-2">Customer Name</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="customerName"
            value={customerDetails.customerName}
            onChange={handleChange}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Location</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="customerAddress"
            value={customerDetails.customerAddress}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-5 mb-5">
        <div className=" w-3/5">
          <label className="block mb-2">Job Note Number</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="jobNoteNumber"
            value={customerDetails.jobNoteNumber}
            onChange={handleChange}
          />
        </div>

        <div className=" w-3/5">
          <label className="block mb-2">Phone Number</label>
          <input
            className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
            type="text"
            name="customerPhone"
            value={customerDetails.customerPhone}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex flex-row justify-end items-center gap-x-2">
        <button
          className={`text-white font-bold p-2 bg-blue-700 rounded-md mb-2 w-36 ${!buttonVisibility && `opacity-50 cursor-not-allowed`}`}
          disabled={isLoading || (products?.length === 0 && !isAllItemsFilled)}
          onClick={confirmButtonHandler}
        >
          {isLoading ? (
            <PropagateLoader color={'white'} loading={isLoading} cssOverride={override} size={5} aria-label="Loading Spinner" data-testid="loader" />
          ) : (
            'Confirm Invoice'
          )}
        </button>

        {/* <button
          className={`text-white  p-2 bg-blue-700 rounded-md mb-2 ${!buttonVisibility && `opacity-50 cursor-not-allowed`}`}
          disabled={isLoading || (products?.length === 0 && !isAllItemsFilled)}
          onClick={() => generatallPDF({ customerDetails, products, totalPrice })}
        >
          <BsPrinterFill size={24} />
        </button> */}
      </div>

      <table className="table-auto w-full z-1">
        <thead className="bg-black sticky top-0">
          <tr>
            <th className="text-white  px-4 py-3 text-left">Product Name</th>
            <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Available Quntity</th>
            {/* <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Cost</th>
            <th className="text-white  px-4 py-3 text-left hidden sm:table-cell">Selling Price</th> */}
            <th className="text-white  px-4 py-3 text-left">Customer Quantity</th>
            <th className="text-white  px-4 py-3 text-sm sm:text-md md:text-base text-left hidden md:table-cell">Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(products).map((product) => {
            const isQuantityExceeded = product.enteredQuantity > product.quantity;
            return (
              <tr className={`odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800 `} key={product.id}>
                <td className={`text-gray-950 p-5 ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>{product.productName}</td>
                <td className={`text-gray-950 p-5 ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>{product.quantity}</td>
                {/* <td className={`text-gray-950 p-5 hidden sm:table-cell ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>
                  {product.unitCost}
                </td>
                <td className={`text-gray-950 p-5 hidden sm:table-cell ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>
                  {product.sellingPrice}
                </td> */}
                <td className={`text-gray-950 p-5 hidden sm:table-cell ${isQuantityExceeded ? 'text-red-600 font-semibold' : ''}`}>
                  <input
                    className="border border-black rounded-md"
                    type="text"
                    onChange={(e) => inputHandler(product.id, parseInt(e.target.value) || 0)}
                    placeholder="Enter quantity..."
                  />
                </td>
                <td className="text-blue-700 p-5 md:flex md:flex-row items-center text-sm sm:text-md md:text-base text-left hidden cursor-pointer">
                  <BsFillTrashFill size={20} title="Delete" onClick={() => removeProductHandler(product.id)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AddDirectJobNote;
