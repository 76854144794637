import React from 'react';

const Modal = ({ open, closeHandler, children }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="fixed z-20 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center text-slate-950 overflow-y-hidden">
      <div className="w-[400px] sm:w-[600px] flex flex-col mt-5 mb-3">
        <button className="text-white text-xl place-self-end" onClick={() => closeHandler()}>
          X
        </button>
        <div className="bg-white p-2 rounded overflow-y-auto max-h-screen">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
